import React, { useState } from 'react'

import Button from '~/components/Button'
import Spinner from '~/components/Spinner'

import BaseModal from '../BaseModal'

const GuestEndCallModal = ({ handleClick, handleExit }) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleExitBtn = () => {
    setIsLoading(true)
    handleExit()
  }

  return (
    <BaseModal>
      <div className='GuestEndCallModal__container'>
        <p>Tem certeza que deseja encerrar a consulta?</p>

        <div className='GuestEndCallModal__container__button'>
          <Button id='leave-call' onButtonClick={() => handleExitBtn()}>
            {
              isLoading ? <Spinner color="white" /> : 'Sim'
            }
          </Button>
          <Button id='close-modal' onButtonClick={() => handleClick()}>
            Não
          </Button>
        </div>
      </div>
    </BaseModal>
  )
}

export default GuestEndCallModal
import React from 'react'

import PropTypes from 'prop-types'

const MicrophoneOn = ({ fill = '#000', height = '24', width = '24' }) =>
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 24 24'>
    <path d='M12,14a2.987,2.987,0,0,0,2.99-3L15,5A3,3,0,0,0,9,5v6A3,3,0,0,0,12,14Zm5.3-3A5.189,5.189,0,0,1,12,16.1,5.189,5.189,0,0,1,6.7,11H5a6.984,6.984,0,0,0,6,6.72V21h2V17.72A6.968,6.968,0,0,0,19,11Z' fill={fill} />
    <path d='M0,0H24V24H0Z' fill='none' />
  </svg>

MicrophoneOn.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string
}

export default MicrophoneOn

import React from 'react'

import PropTypes from 'prop-types'

import { messageComponents } from './messageComponents'

export const FeedbackMessageManager = ({ message }) => {
  const Message = message || messageComponents.PreparingRoomMessage
  return (<div className='FeedbackMessageManager'><Message /></div>)
}

FeedbackMessageManager.propTypes = {
  message: PropTypes.elementType
}
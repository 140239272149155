import React from 'react'

import PropTypes from 'prop-types'

const Clock = ({ fill = '#000', height = '20', width = '20' }) =>
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 20 20'>
    <g transform='translate(-2 -2)'>
      <g>
        <path d='M12,2A10,10,0,1,0,22,12,10.029,10.029,0,0,0,12,2Zm3.55,13.8-4.08-2.51a.986.986,0,0,1-.48-.85V7.75A.771.771,0,0,1,11.75,7a.755.755,0,0,1,.75.75V12.2l3.84,2.31a.756.756,0,1,1-.79,1.29Z' fill={fill} />
      </g>
    </g>
  </svg>

Clock.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string
}

export default Clock

import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { AlreadyConnectedError, BrowserError, ConnectionError, TimesUpError, UnexpectedError, errorTypes } from './Errors'

import { ReactComponent as Logo } from '~/static/svg/logo-telavita.svg'

const ErrorView = () => {
  const location = useLocation()
  const getError = location?.state || errorTypes.UNEXPECTED

  useEffect(() => {
    sessionStorage.clear()
  }, [])

  return (
    <div className='ErrorViewMeeting'>
      <div className='LogoContainer'>
        <Logo />
      </div>
      {getError.errorType === errorTypes.ALREADY_CONNECTED && <AlreadyConnectedError />}
      {getError.errorType === errorTypes.BROWSER && <BrowserError />}
      {getError.errorType === errorTypes.CONNECTION && <ConnectionError />}
      {getError.errorType === errorTypes.TIME_UP && <TimesUpError />}
      {!getError.errorType && <UnexpectedError />}
    </div>
  )
}

export default ErrorView

import React from 'react'
import ReactDOM from 'react-dom'

import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import App from './App'
import { apm } from './modules/Apm'
import './static/styles/styles.sass'
import * as serviceWorker from './serviceWorker'

apm.setInitialPageLoadName('Meet Web App APM started.')

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new BrowserTracing(),
    new Sentry.Replay()
  ],
})

ReactDOM.render(
  <App />,
  document.getElementById('root')
)

serviceWorker.unregister()

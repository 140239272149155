import React from 'react'

import { format } from 'date-fns'

import { Button, Icon, Typography } from '@telavita-core/react-design-kit'

const Success = ({
  conflicts,
  time,
  name,
  onClose,
  exceptionsDates = []
}) => {
  return (
    <div className='Reservation__success'>
      <Icon
        name='Check'
        color='success'
        height={50}
        width={50}
        customClassName='Reservation__success__icon'
      />
      <Typography variant='header4'>
        Reserva efetuada com sucesso!
      </Typography>

      <div className='Reservation__successAgenda'>
        <Typography color='success' weight='bold'>
          {time.weekDayPlural}
        </Typography>
        <Typography color='success' weight='bold'>
          às {time.hours}
        </Typography>
      </div>

      {conflicts && exceptionsDates?.length > 0
        ? (
          <div className='Reservation__exceptionsDates'>
            <Typography variant='content2' weight='bold'>Exceto em:</Typography>

            {exceptionsDates?.map((date, index) => {
              return (
                <Typography variant='content2' key={index}>
                  <strong>{format(new Date(date), 'dd/MM/yyyy')}</strong>, 
                  reservado para <strong>{format(new Date(date), 'HH:mm')}</strong>
                </Typography>
              )
            })}
          </div>
        ) 
        : null
      }

      <FeedbackBox name={name} />

      <Button
        variant='contained'
        color='primary'
        customClassName='Reservation__button'
        onClick={onClose}
        inlineStyles={{
          fontWeight: '700',
        }}
      >
        FECHAR
      </Button>
    </div>
  )
}


const FeedbackBox = ({ name }) => {
  return (
    <div className='Reservation__feedback'>
      
      <Typography variant='content2' weight='bold'>
        INFORME AO PACIENTE:
      </Typography>
      
      <Typography variant='content2' customClassName='Reservation__feedbackText'>
        <strong>1)</strong> {name}, você deve continuar agendando suas consultas normalmente. 
        A diferença é que, a partir de agora, este dia e horário vão aparecer{' '}
        <strong>somente pra você </strong>ao agendar.
      </Typography>

      <Typography variant='content2' customClassName='Reservation__feedbackText'>
        <strong>2)</strong> A confirmação da reserva será enviada por e-mail com todos os detalhes.
      </Typography>

    </div>
  )
}

export default Success
import React from 'react'

import { Paper, Typography } from '@telavita-core/react-design-kit'

import * as S from './styles'

export const PatientAlreadyHasReservation = ({ patientFirstName }) => {
  return (
    <S.PatientAlreadyHasReservationWrapper>
      <Typography variant='content2'> Este paciente já possui uma reserva.</Typography>
  
      <Paper variant='warning'>
        <Typography variant='content2' weight='bold'> INFORME AO PACIENTE: </Typography>
  
        <Typography variant='content2'>
          {patientFirstName}, para criar uma nova reserva, primeiro você deve cancelar a atual, dentro de sua conta.
        </Typography>
      </Paper>
    </S.PatientAlreadyHasReservationWrapper>
  )
}
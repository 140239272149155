export const twilioMediaErrorHandler = (errorName='TypeError', errorMessage='nor defined') => {
  const deviceType = () => {
    if (errorMessage.includes('video')) return 'câmera'
    if (errorMessage.includes('audio')) return 'microfone'
    return 'câmera e microfone'
  }

  const twilioMediaErrors = {
    NotFoundError: `O sistema não permitiu usar ${deviceType()}, ou o dispositivo não foi encontrado.`,
    NotAllowedError: `Você precisa autorizar o uso de ${deviceType()}.`,
    TypeError: `O sistema não permitiu usar ${deviceType()}, ou o dispositivo não foi encontrado.`,
    NotReadableError: `O dispositivo ${deviceType()} não está disponível.`,
    OverconstrainedError: `O sistema não permitiu usar ${deviceType()}, ou o dispositivo não foi encontrado.`,
  }

  return twilioMediaErrors[errorName]
}

import React, { useEffect, useState } from 'react'

import { addWeeks, isSameWeek } from 'date-fns'

import { Button, Select, Spinner, Typography } from '@telavita-core/react-design-kit'

import { NonRecurrentReservationBox } from './NonRecurrentReservationBox/NonRecurrentReservationBox'
import Success from './Success'

import { axios, endpoints } from '~/settings'
import { WEEKDAY_NAMES_WITH_START_ON_MONDAY } from '~/utils/dateConstants'

const getPlural = day => day.split('-').map(word => word + 's').join('-')

const getHoursAndMinutes = date => date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0')

const extractTimeString = (weekDay, startDate) => {
  const weekDayPlural = getPlural(weekDay)
  const hours = getHoursAndMinutes(new Date(startDate))
  return {
    weekDayPlural,
    hours
  }
}

const NewReservation = ({
  professional,
  patient,
  success,
  setSuccess,
  patientPlanCode,
  handleCloseDrawerOnSuccess
}) => {
  const [loadingAvailabilities, setLoadingAvailabilities] = useState(true)
  const [availabilities, setAvailabilities] = useState(null)
  const [days, setDays] = useState([])
  const [schedules, setSchedules] = useState([])
  const [selectedDay, setSelectedDay] = useState(null)
  const [selectedSchedule, setSelectedSchedule] = useState(null)
  const [professionalRestrictions, setProfessionalRestrictions] = useState(null)
  const [loadingRestrictions, setLoadingRestrictions] = useState(false)
  const [submiting, setSubmiting] = useState(false)
  const [nonRecurrentReservationDates, setNonRecurrentReservationDates] = useState([])
  const [disableCreateReservationButton, setDisableCreateReservationButton] = useState(false)

  const hasRestrictions = professionalRestrictions?.restrictions.length > 0
  const hasNonRecurrentReservationDates = nonRecurrentReservationDates
    .every(date => Boolean(date)) && nonRecurrentReservationDates.length > 0

  function handleNonRecurrentReservationDate(date, slotIndex) {
    const newNonRecurrentReservationDates = [...nonRecurrentReservationDates]
    newNonRecurrentReservationDates[slotIndex] = date
    setNonRecurrentReservationDates(newNonRecurrentReservationDates)
  }

  useEffect(() => {
    if (hasRestrictions || !hasNonRecurrentReservationDates) {
      setDisableCreateReservationButton(true)
    } 

    if ((hasRestrictions && hasNonRecurrentReservationDates) || !hasRestrictions) {
      setDisableCreateReservationButton(false)
    }

  }, [nonRecurrentReservationDates, professionalRestrictions, hasRestrictions])

  useEffect(() => {
    const fetchAvailabilities = async () => {
      try {
        const response = await axios.get(endpoints.availabilities.AVAILABILITIES(professional.username, patient.username))
        const { availabilities } = response.data.data

        const days = Object.keys(availabilities).filter(key => availabilities[key].length).map(key => ({
          id: +key,
          label: WEEKDAY_NAMES_WITH_START_ON_MONDAY[key]
        }))

        setDays(days)
        setAvailabilities(availabilities)
        setLoadingAvailabilities(false)
      } catch (e) { setLoadingAvailabilities(false) }
    }
    fetchAvailabilities()
  }, [professional, patient])

  useEffect(() => {
    if (selectedDay) {
      const getSchedulesFromSelectedDay = () => {
        const { id } = selectedDay
        const schedules = availabilities[id]
        return schedules.map((schedule, i) => {
          const date = new Date(schedule.start_date)
          return {
            id: i,
            label: getHoursAndMinutes(date),
            handler: () => schedule
          }
        })
      }
      setSchedules(getSchedulesFromSelectedDay())
      setSelectedSchedule(null)
    }
  }, [selectedDay])

  useEffect(() => {
    if (selectedSchedule) {
      const checkProfessionalRestrictions = async () => {
        const selectedDate = selectedSchedule.handler()
        const isSameWeekAsNow = isSameWeek(new Date(), new Date(selectedDate.start_date))
        const startDate = isSameWeekAsNow ? addWeeks(new Date(selectedDate.start_date), 1) : new Date(selectedDate.start_date)
        const endDate = isSameWeekAsNow ? addWeeks(new Date(selectedDate.end_date), 1) : new Date(selectedDate.end_date)
        
        try {
          const response = await axios.get(endpoints.restrictions.RESTRICTIONS('professional', professional.username, {
            start_date: startDate.toISOString(),
            end_date: endDate.toISOString(),
            patient_username: patient.username,
            plan_code: patientPlanCode
          }))
          setProfessionalRestrictions(response.data.data)
          setLoadingRestrictions(false)
        } catch (e) {
          setLoadingRestrictions(false)
          setProfessionalRestrictions(e.data)
          setSelectedSchedule(null)
        }
      }
      setLoadingRestrictions(true)
      checkProfessionalRestrictions()
    }
  }, [selectedSchedule])

  const onSelectDay = day => {
    setSelectedDay(day)
    setProfessionalRestrictions(null)
  }

  if (loadingAvailabilities)
    return <Spinner customClassName='Reservation__spinner__fullScreen' />


  const onSelectSchedule = schedule => {
    setSelectedSchedule(schedule)
    setNonRecurrentReservationDates([])
  }
  
  const nonRecurrentReservationsDates = nonRecurrentReservationDates?.map(dateString => {
    if (!dateString) return null

    const startDate = new Date(dateString)
    const endDate = new Date(startDate)
    endDate.setMinutes(endDate.getMinutes() + 49, endDate.getSeconds() + 59)
  
    return {
      start_date: startDate?.toISOString(),
      end_date: endDate?.toISOString(),
    }
  })
  
  const onSubmit = async () => {
    try {
      setSubmiting(true)
      const payload = {
        professional_username: professional.username,
        patient_username: patient.username,
        start_date: professionalRestrictions.reservation_start_date,
        end_date: professionalRestrictions.reservation_end_date,
        plan_code: patientPlanCode,
        recurrent: true,
        ...(hasNonRecurrentReservationDates && { non_recurrent_reservations: nonRecurrentReservationsDates })
      }
      const { status } = await axios.post(endpoints.reservations.RESERVATION, payload)

      if (status === 201) {
        setSubmiting(false)
        setSuccess(true)
      }

    } catch (e) {
      setSuccess(false)
      setSubmiting(false)
    }
  }

  const showButton = selectedSchedule && Boolean(professionalRestrictions) && !loadingRestrictions

  if (success)
    return (
      <Success
        conflicts={Boolean(professionalRestrictions?.restrictions.length)}
        time={extractTimeString(selectedDay.label, professionalRestrictions.reservation_start_date)}
        name={patient.first_name}
        onClose={handleCloseDrawerOnSuccess}
        exceptionsDates={nonRecurrentReservationDates}
      />
    )

  if (submiting)
    return (
      <div className='Reservation__content'>
        <Spinner customClassName='Reservation__spinner__fullScreen' />
      </div>
    )

  return (
    <div className='Reservation__content'>
      <Select
        options={days}
        selected={selectedDay}
        onSelect={onSelectDay}
        label='Escolha um dia da semana'
        customClassName='Reservation__select'
      />

      {selectedDay &&
        <Select
          options={schedules}
          selected={selectedSchedule}
          onSelect={onSelectSchedule}
          label='Escolha um de seus horários recorrentes'
          customClassName='Reservation__select'
        />
      }

      {loadingRestrictions && <Spinner customClassName='Reservation__spinner' />}
      
      {Boolean(professionalRestrictions?.restrictions.length) && !loadingRestrictions && (
        <NonRecurrentReservationBox
          restrictions={professionalRestrictions.restrictions} 
          handleNonRecurrentReservationDate={handleNonRecurrentReservationDate}
        />
      )}
      
      {
        !loadingRestrictions &&
          <div className='Reservation__timezone'>
            <Typography variant='content3' inheritColor>
              *Brasil (UTC−3) - Horário de Brasília Padrão 00h00 a 23h59
            </Typography>
          </div>
      }
      {
        showButton &&
          <Button
            variant='contained'
            color='primary'
            disabled={disableCreateReservationButton}
            customClassName='Reservation__button'
            onClick={onSubmit}
            inlineStyles={{
              fontWeight: '700',
            }}
          >
            Concluir
          </Button>
      }
    </div>
  )
}

export default NewReservation

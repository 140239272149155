
import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import { ErrorBoundary } from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { ThemeProvider } from '@telavita-core/react-design-kit'

import ErrorView from '~/views/ErrorView'
import MeetingView from '~/views/MeetingView'

import { ScheduleProvider } from './contexts/ScheduleContext'

const queryClient = new QueryClient()

const App = () => {
  return (
    <div className='App'>
      <ErrorBoundary
        fallback={<ErrorView />}
        showDialog
        dialogOptions={{
          title: 'Oops! Parece que você encontrou um erro.',
          labelSubmit: 'Enviar erros',
          successMessage: 'Sua mensagem foi enviada com sucesso. Obrigado!',
        }}
      >
        <QueryClientProvider client={queryClient}>
          <ThemeProvider>
            <ScheduleProvider>
              <BrowserRouter>
                <Switch>
                  <Route path='/error' component={ErrorView} />
                  <Route path='/:hash' component={MeetingView} />
                  <Route path='/' component={ErrorView} />
                </Switch>
              </BrowserRouter>
            </ScheduleProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </ErrorBoundary>
    </div>
  )
}

export default App

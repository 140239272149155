import * as Sentry from '@sentry/react'

import { axios, endpoints } from '~/settings'

export const fetchApi = {
  sendLogToServer: async (hash, eventData) => {
    if (!hash) return
    try {
      await axios.post(endpoints.virtualRooms.LOG(hash), eventData)
    } catch (err) {
      Sentry.captureException(new Error(`LOG API Failed on event: ${eventData.event}`))
    }
  }
}
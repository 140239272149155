import React from 'react'

import PropTypes from 'prop-types'

const NetworkSignal = ({ networkSignal }) => {
  return (
    <div className='NetworkSignal'>
      <div className="NetworkSignal__container">
        <div className={`NetworkSignal__bars${networkSignal ? networkSignal <= 2 ? '-disabled-red' : '' : '-disabled'}`} id="signal-one" />
        <div className={`NetworkSignal__bars${networkSignal ? networkSignal <= 2 ? '-disabled-red' : '' : '-disabled'}`} id="signal-two" />
        <div className={`NetworkSignal__bars${networkSignal < 3 ? '-disabled' : ''}`} id="signal-three" />
        <div className={`NetworkSignal__bars${networkSignal < 4 ? '-disabled' : ''}`} id="signal-four" />
        <div className={`NetworkSignal__bars${networkSignal < 5 ? '-disabled' : ''}`} id="signal-five" />
      </div>
    </div>
  )
}

NetworkSignal.propTypes = {
  networkSignal: PropTypes.number
}

NetworkSignal.defaultProps = {
  networkSignal: 3
}

export default NetworkSignal
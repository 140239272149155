import moment from 'moment'

export const dateTimeHandler = {
  getDateFromTimestamp: (timestamp) => {
    return moment.unix(timestamp)
  },

  getDurationInMs: (startTime, endTime) => {
    const inititialDate = startTime ? moment.utc(startTime) : moment.utc()
    const finalDate = endTime ? moment.utc(endTime) : moment.utc()
    return finalDate.diff(inititialDate)
  },

  getDurationInMinutes: (startDate, endDate) => {
    const inititialDate = startDate ? moment.utc(startDate) : moment.utc()
    const finalDate = endDate ? moment.utc(endDate) : moment()
    return moment.duration(finalDate.diff(inititialDate)).minutes()
  },

  isNowBeforeDate: (endDate) => {
    const dateTimeNow = moment.utc()
    const finalDateTime = moment.utc(endDate)
    return dateTimeNow.isBefore(finalDateTime)
  },

  getTime: (date) => {
    const getDate = date ? moment(date) : moment()
    return getDate.format('HH:mm')
  }
}
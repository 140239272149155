import React from 'react'

import { Button, Paper, Typography } from '@telavita-core/react-design-kit'

import * as S from './styles'

export const PatientAlreadyHasSchedule = ({ 
  patientFirstName, 
  totalSchedules = 0,
  handleContinueWithReservation 
}) => {
  return (
    <S.PatientAlreadyHasScheduleWrapper>
      <Paper variant='warning'>
        <Typography variant='content2' weight='bold'> INFORME AO PACIENTE: </Typography>
        <Typography variant='content2'>
          {patientFirstName}, você possui <strong>{totalSchedules} consultas <br /> agendadas por você.</strong>
        </Typography>
  
        <Typography variant='content2'>
            Ao concluir a criação da reserva, <strong>consultas agendadas por meio do convênio 
            são canceladas para serem substituídas</strong> pelo dia e horário definidos na reserva.
        </Typography>
  
        <Typography variant='content2'>
          Deseja continuar?
        </Typography>
      </Paper>
      <Button onClick={handleContinueWithReservation} variant='contained'>Continuar</Button>
    </S.PatientAlreadyHasScheduleWrapper>
  )
}
import { min, max, parseISO, format, startOfDay, endOfDay, formatISO } from 'date-fns'

export function getMinAndMaxDatesFromDateList(dateArray) {
  const dateArrayAsDateObjects = dateArray.map(dateString => parseISO(dateString))
  const startDate = min(dateArrayAsDateObjects)
  const endDate = max(dateArrayAsDateObjects)
  
  return { 
    start_date: formatISO(startOfDay(startDate)),
    end_date: formatISO(endOfDay(endDate)),
  }
}

export function getDaysWithEvents(events, days) {
  const result = {}
  for (const day of days) {
    if (Object.prototype.hasOwnProperty.call(events, day)) {
      result[day] = events[day]
    }
  }
  return result
}

export function formatEvents(daysWithEvents) {
  const formattedEvents = []

  for (const day in daysWithEvents) {
    const events = daysWithEvents[day]
    const formattedDay = {}

    if (events.length > 0) {
      formattedDay[day] = events
        .filter(event => {
          const eventDate = new Date(event.start_date)
          eventDate.setHours(23)
          return eventDate.toISOString()
        })
        .map(event => format(new Date(event.start_date), 'yyyy-MM-dd\'T\'HH:mm:ss'))
    } else {
      formattedDay[day] = []
    }

    formattedEvents.push(formattedDay)
  }

  return formattedEvents
}
import axios from 'axios'
import momentTz from 'moment-timezone'

const cookieName = process.env.REACT_APP_SESSION_COOKIE_NAME
const apiUrl = process.env.REACT_APP_API_URL

const getTokenFromCookie = () => {
  let name = cookieName + '='
  let decodedCookie = decodeURIComponent(document.cookie)
  let ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ')
      c = c.substring(1)
    if (c.indexOf(name) === 0)
      return c.substring(name.length, c.length)
  }
  return null
}

/** 
 * Axios Instance 
**/
const instance = axios.create({ baseURL: `${apiUrl}` })
/* Global axios interceptor for requests */
instance.interceptors.request.use(requestConfig => {
  requestConfig.headers = {
    ...instance.defaults.headers,
    'Time-Zone': momentTz.tz.guess(),
  }

  const AUTH_TOKEN = getTokenFromCookie()
  requestConfig.headers['Authorization'] = `Bearer ${AUTH_TOKEN}`
  sessionStorage.setItem('token', AUTH_TOKEN)
  return requestConfig
}, err => { return Promise.reject(err) })

/* Global axios interceptor for responses */
instance.interceptors.response.use(response => {
  return response
}, err => {
  return Promise.reject(err.response)
})

export default instance
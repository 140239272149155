import React from 'react'

import PropTypes from 'prop-types'

const X = ({ fill = '#000', height = '35', width = '35' }) =>
  <svg
    height={height}
    width={width}
    viewBox="0 0 35 35" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <g 
      fill={fill}
    >
      <path d="M25 11.41L23.59 10 18 15.59 12.41 10 11 11.41 16.59 17 11 22.59 12.41 24 18 18.41 23.59 24 25 22.59 19.41 17z"></path>
    </g>
  </svg>

X.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
}

export default X
import React from 'react'

import { FaceId as FaceIdContainer } from '@telavita-core/react-faceid'

import { endpoints, externalRoutes } from '~/settings'

const domain = window.location.hostname.includes('localhost') ? 'localhost' : 'telavita.com.br'
const cookieName = process.env.REACT_APP_SESSION_COOKIE_NAME

export default function FaceId() {
  const handleCookie = (newToken) => {
    let d = new Date()
    d.setTime(d.getTime() + (1 * 24 * 60 * 60 * 1000))
    let expires = 'expires=' + d.toUTCString()
    document.cookie = cookieName + '=' + newToken + ';' + expires + ';path=/;domain=' + domain
  }

  const onSuccess = (newToken) => {
    handleCookie(newToken)
    window.location.reload()
  }

  const onError = () => {
    window.location.assign(externalRoutes.DASHBOARD)
  }

  return (
    <FaceIdContainer
      authenticates={true}
      token={`Bearer ${sessionStorage.getItem('token')}`}
      endpoint={endpoints.authentication.FACE_ID}
      fallbackAction={onError}
      successEvent={(newToken) => onSuccess(newToken)}
      title="Reconhecimento facial"
    />
  )
}

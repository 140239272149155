import React from 'react'

import Button from '~/components/Button'

import { externalRoutes } from '~/settings'

export const ConnectionError = () => {
  return (
    <div className='ConnectionError'>
      <div className='ConnectionError__title'>
        <p>Nenhuma conexão de rede</p>
      </div>

      <div className='ConnectionError__message'>
        <p>Você não está conectado à internet. Verifique sua conexão e tente entrar novamente.</p>
      </div>

      <div className='ConnectionError__button'>
        <Button id='exit' onButtonClick={() => window.location.assign(externalRoutes.DASHBOARD)}>
          entrar novamente
        </Button>
      </div>
    </div>
  )
}
import React from 'react'

import { format, getDay } from 'date-fns'

import { Typography, Icon, Button } from '@telavita-core/react-design-kit'

import * as S from './styles'

import { PLURAL_WEEKDAY_NAMES_WITH_START_ON_SUNDAY } from '~/utils/dateConstants'

export const SuccessContent = ({
  handleClose,
  newRecurrentStartDate,
  patientFirstName,
  exceptions,
}) => {

  const weekDay = PLURAL_WEEKDAY_NAMES_WITH_START_ON_SUNDAY[getDay(newRecurrentStartDate)]
  const hours = format(newRecurrentStartDate, 'HH:mm')

  return (
    <S.SuccessReservationContainer>
      
      <Icon
        name='Check'
        color='success'
        height={50}
        width={50}
      />
      
      <Typography variant='header4' center>
        Reserva recorrente <br />modificada com sucesso!
      </Typography>
    
      <S.SuccessMessageTime>
        <Typography color='success' weight='bold'>
          {weekDay}
        </Typography>
        <Typography color='success' weight='bold'>
           às {hours}
        </Typography>
      </S.SuccessMessageTime>

      {exceptions?.length > 0
        ? (
          <div>
            <Typography variant='content2' center weight='bold'>Exceto em:</Typography>

            {exceptions?.map((date, index) => {
              return (
                <Typography variant='content2' key={index}>
                  <strong>{format(new Date(date.newDate), 'dd/MM/yyyy')}</strong>, 
                  reservado para <strong>{format(new Date(date.newDate), 'HH:mm')}</strong>
                </Typography>
              )
            })}
          </div>
        ) 
        : null
      }

      <S.Box>

        <Typography
          weight='bolder'
          variant='content2'
        >
          INFORME AO PACIENTE:
        </Typography>

        <Typography
          variant='content2'
        >
          <b>1)</b>
          {' '}{patientFirstName || 'Paciente'}, você deve continuar agendando suas consultas normalmente. A diferença é que, a partir de agora, este dia e horário vão aparecer
          <b>{' '} somente pra você</b> ao agendar.
        </Typography>

        <Typography
          variant='content2'
        >
          <b>{'2) '}</b>A confirmação da reserva será enviada por e-mail com todos os detalhes.
        </Typography>

      </S.Box>

      <Button
        variant='contained'
        uppercase
        onClick={handleClose}
      >
        Fechar
      </Button>

    </S.SuccessReservationContainer>
  )
}
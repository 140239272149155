import ReactCalendar from 'react-calendar'

import styled from 'styled-components'

import { colors } from '~/utils/stylesConstants'

import 'react-calendar/dist/Calendar.css'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 25px;
`

export const CalendarHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;

  > p:nth-child(2) {
    margin-right: 5px;
    text-transform: uppercase;
  }
`

export const CalendarContainer = styled(ReactCalendar)`
  border: none;
  width: 100%;

  &&& {
    .react-calendar__navigation {
      display: none;
    }

    .react-calendar__month-view__days {
      margin-top: 15px;
    }

    .react-calendar__month-view__weekdays__weekday {
      overflow: visible;
      padding: 0;
      width: fit-content;
    }

    .react-calendar__month-view__days__day--weekend[disabled] {
      display: none;
    }

    .react-calendar__month-view__days__day[disabled] {
      display: none;
    }

    .react-calendar__month-view__weekdays__weekday abbr {
      font-family: 'Ubuntu', sans-serif;
      text-decoration: none;
      font-size: 15px;
      text-transform: capitalize;
      color: ${colors.BLUE_03}
    }

    .react-calendar__tile--active {
      color: ${colors.WHITE};
      font-weight: bold;
      border: none;
    }

    .react-calendar__tile {
      font-family: 'Ubuntu', sans-serif;
      color: ${colors.GREY_07};
      height: 42px;
      width: 42px;
      padding: 0;
      border: 1px solid ${colors.WHITE};
      font-size: 15px;
    }

    .react-calendar__tile--now {
      color: ${colors.PRIMARY};
      background-color: transparent;
    }

    .react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus{
        background-color: transparent;
      }

    .react-calendar__navigation button[disabled] {
      background-color: transparent;
    }

    .react-calendar__tile--range {
      background-color: ${colors.BLUE_02};
      color: ${colors.WHITE};
    }

    .react-calendar__tile--hover {
      background-color: ${colors.BLUE_02};
      color: ${colors.WHITE};
    }

    .react-calendar__tile--rangeStart, .react-calendar__tile--rangeEnd {
      background-color: ${colors.PRIMARY};
      color: ${colors.WHITE};
    }
  }
`

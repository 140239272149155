import React from 'react'

import CustomIcons, { iconList } from '~/components/CustomIcons'

import { colors } from '~/utils/stylesConstants'

export const TipsMessageBox = () => {
  return (
    <div className='TipsMessageBox'>
      <div className='TipsMessageBox__header'>
        <div className='TipsMessageBox__header__title'>
          <p>Dicas importantes</p>
        </div>
        <div className='TipsMessageBox__header__separator' />
      </div>

      <div className='TipsMessageBox__tips'>
        <div className='TipsMessageBox__tips__tipbox'>
          <CustomIcons icon={iconList.Wifi} fill={colors.GREY_04} />

          <div className='TipsMessageBox__tips__tipbox__message'>
            <p>{'Conecte-se por Wi-fi e procure ficar próximo do modem para garantir uma melhor conexão.'}</p>
          </div>
        </div>

        <div className='TipsMessageBox__tips__tipbox'>
          <CustomIcons icon={iconList.CameraTip} fill={colors.GREY_04} />

          <div className='TipsMessageBox__tips__tipbox__message'>
            <p>{'Verifique se sua câmera e microfone estão funcionando. Você pode desabilitá-los nos botões acima.'}</p>
          </div>
        </div>

        <div className='TipsMessageBox__tips__tipbox'>
          <CustomIcons icon={iconList.Sofa} fill={colors.GREY_04} />

          <div className='TipsMessageBox__tips__tipbox__message'>
            <p>{'Escolha um ambiente silencioso e privativo. Este momento é seu, aproveite ao máximo!'}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

import React from 'react'

import { Typography } from '@telavita-core/react-design-kit'

const Tabs = ({ 
  onSelectTab,
  selectedItem,
  tabs,
}) => {
  return (
    <div className="Tabs">
      {tabs.map(item => {
        const isSelected = item.id === selectedItem

        return (
          <div
            key={item.id}
            className={`Tabs__item${isSelected ? '-selected' : ''}`}
            onClick={() => onSelectTab(item.id)}
          >
            <Typography variant='content2'>
              {item.title}
            </Typography>
          </div>
        )
      })}
    </div>
  )
}

export default Tabs

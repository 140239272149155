import { useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { addMinutes, isWithinInterval, parseISO, subMinutes } from 'date-fns'

import { axios, endpoints } from '~/settings'

const PARTICULAR_PLAN_CODE = 'PLN01000'
const SCHEDULED_STATUS_CODE = 'STS21002'
const PATIENT_SCHEDULE_LIST_QUERY_KEY = 'PATIENT_SCHEDULE_LIST'

async function fetchPatientScheduleList(username) {
  const params = {
    username: username,
    status_code: [SCHEDULED_STATUS_CODE]
  }
  
  const { data } = await axios.get(endpoints.schedules.SCHEDULES({ ...params }))
  return data
}
  
const removeParticularSchedule = (scheduleList) => {
  return scheduleList
    .filter(schedule => schedule?.order_item?.order.plan_code !== PARTICULAR_PLAN_CODE)
}

const removeScheduleWhichStartNow = (scheduleList) => {
  const INITIAL_INTERVAL_IN_MINUTES = 5
  const FINAL_INTERVAL_IN_MINUTES = 60
  
  return scheduleList
    .filter(schedule => {
      const scheduleDate = parseISO(schedule?.start_date)
      const initialInterval = subMinutes(scheduleDate, INITIAL_INTERVAL_IN_MINUTES)
      const finalInterval = addMinutes(scheduleDate, FINAL_INTERVAL_IN_MINUTES)
      const currentDate = new Date()

      return !isWithinInterval(currentDate, { start: initialInterval, end: finalInterval })
    })
}

export const usePatientScheduleList = (username, shouldFetch) => {
  const [patientScheduleList, setPatientScheduleList] = useState(null)
  
  useQuery({
    queryKey: [PATIENT_SCHEDULE_LIST_QUERY_KEY, username],
    queryFn: () => fetchPatientScheduleList(username),
    onSuccess: (data) => {
      const schedules = data?.data?.schedules
      const removedParticularSchedulesFromList = removeParticularSchedule(schedules)
      const removedCurrentScheduleFromList = removeScheduleWhichStartNow(removedParticularSchedulesFromList)
      setPatientScheduleList(removedCurrentScheduleFromList)
    },
    onError: () => {
      setPatientScheduleList(null)
    },
    enabled: !!username && shouldFetch,
    retry: 1,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  })
  
  return patientScheduleList
}
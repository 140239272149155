import Alert from './Icons/Alert'
import CallEnd from './Icons/CallEnd'
import CameraOff from './Icons/CameraOff'
import CameraOn from './Icons/CameraOn'
import CameraTip from './Icons/CameraTip'
import Chat from './Icons/Chat'
import Check from './Icons/Check'
import Clock from './Icons/Clock'
import Close from './Icons/Close'
import MicrophoneOff from './Icons/MicrophoneOff'
import MicrophoneOn from './Icons/MicrophoneOn'
import Send from './Icons/Send'
import Sofa from './Icons/Sofa'
import Speaker from './Icons/Speaker'
import Wifi from './Icons/Wifi'
import X from './Icons/X'

export const iconList = {
  Alert,
  CallEnd,
  CameraOff,
  CameraOn,
  CameraTip,
  Chat,
  Check,
  Clock,
  Close,
  MicrophoneOff,
  MicrophoneOn,
  Send,
  Sofa,
  Speaker,
  Wifi,
  X,
}

import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import * as Sentry from '@sentry/react'
import { VideoRoomMonitor } from '@twilio/video-room-monitor'
import throttle from 'lodash.throttle'
import Video, { LocalDataTrack } from 'twilio-video'

import { toast } from '@telavita-core/react-design-kit'

import { errorTypes } from '~/views/ErrorView/Errors'
import { RescheduleReservation, Reservation } from '~/views/Reservation'
import { views } from '~/views/viewsCode'

import CallControllers from '~/components/CallControllers'
import CallHeader from '~/components/CallHeader'
import CallHeaderAlert from '~/components/CallHeaderAlert'
import ChatContainer from '~/components/ChatContainer'
import CustomIcons, { iconList } from '~/components/CustomIcons'
import FeedbackCallMessageBox from '~/components/FeedbackCallMessageBox'
import GuestEndCallModal from '~/components/GuestEndCallModal'
import NetworkSignal from '~/components/NetworkSignal'
import PatientDetails from '~/components/PatientDetails'

import { DevicesContext } from '~/contexts'
import { fetchApi } from '~/handlers'
import { useGetReservation } from '~/hooks'
import { externalRoutes } from '~/settings'
import { colors } from '~/utils/stylesConstants'

const TWILIO_CONNECT_OPTIONS = {
  audio: true,
  video: true,
  tracks: [],
  preferredVideoCodecs: [{ codec: 'VP8', simulcast: false }],
  bandwidthProfile: {
    video: {
      mode: 'grid',
      trackSwitchOffMode: 'detected',
      clientTrackSwitchOffControl: 'manual'
    }
  },
  maxAudioBitrate: 16000,
  networkQuality: { local: 1, remote: 1 },
}

let timer

const CallRoom = ({
  updateView,
  accessToken,
  meeting,
  onOpenSettingsModal,
}) => {
  const localVideoRef = useRef()
  const remoteAudioRef = useRef()
  const remoteVideoRef = useRef()

  const history = useHistory()
  const {
    userAudioTrack,
    userVideoTrack,
    activeSpeakerDevice,
    audioEnabled,
    videoEnabled,
    handleToggleAudio,
    handleToggleVideo,
    mediaErrorMessage
  } = useContext(DevicesContext)

  const [room, setRoom] = useState(null)
  const [participant, setParticipant] = useState(null)
  const [participantNetwork, setParticipantNetwork] = useState(null)
  const [participantDisconnection, setParticipantDisconnection] = useState(null)
  const [participantReconnection, setParticipantReconnection] = useState(false)
  const [participantLeft, setParticipantLeft] = useState(false)

  const [localDataTrack, setLocalDataTrack] = useState(null)
  const [localNetwork, setLocalNetwork] = useState(null)

  const [isChatOpen, setIsChatOpen] = useState(false)
  const [chatMessage, setChatMessage] = useState(null)
  const [toggleDisplay, setToggleDisplay] = useState(true)
  const [canHideElementsMobile, setCanHideElementsMobile] = useState(true)
  const [canHideElementsDesktop, setCanHideElementsDesktop] = useState(false)
  const [mouseOver, setMouseOver] = useState(false)

  const [showRemoteVideo, setShowRemoteVideo] = useState(true)
  const [showRemoteAudio, setShowRemoteAudio] = useState(true)

  const [remoteAudioTrack, setRemoteAudioTrack] = useState(null)
  const [unsubscribedRemoteAudioTrack, setUnsubscribedRemoteAudioTrack] = useState(null)

  const [remoteVideoTrack, setRemoteVideoTrack] = useState(null)
  const [unsubscribedRemoteVideoTrack, setUnsubscribedRemoteVideoTrack] = useState(null)

  const [showGuestEndCallModal, setShowGuestEndCallModal] = useState(false)
  const [localMessages, setLocalMessages] = useState('Aguardando participante entrar')
  const [startInterval, setStartInterval] = useState(false)

  const [reservationOpen, setReservationOpen] = useState(false)
  const [rescheduleReservationOpen, setRescheduleReservationOpen] = useState(false)

  const [patientDetailsOpen, setPatientDetailsOpen] = useState(false)

  const { schedule: { guests } } = meeting

  const professional = guests.find(u => ['TVTVPSIC', 'TVTVPSIQ'].includes(u.profile_code))

  const { reservation, refetch: refetchReservation } = useGetReservation({
    professionalUsername: professional?.username,
    patientUsername: meeting.guest.username,
  })
  
  const isPsychologist = professional?.profile_code === 'TVTVPSIC'
  
  const patientData = {
    ...guests.find(u => u.profile_code === 'TVTVPACI'),
    full_name: meeting.guest.full_name,
    picture_url: meeting.guest.picture_url
  }

  useEffect(() => {
    if (!accessToken) {
      updateView(views.waitingRoom)
    }

    if (!room) {
      console.log('conectando com a twilio')
      Video
        .connect(accessToken, TWILIO_CONNECT_OPTIONS)
        .then(room => {
          console.log('conectado: ', room)
          setRoom(room)
        })
        .catch(err => {
          Sentry.captureException(err)
          history.replace('error')
          // TODO: Mapear erros do connect
        })
    }
  }, [accessToken])

  // Twilio Monitor
  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'development') {
      VideoRoomMonitor.registerVideoRoom(room)
      VideoRoomMonitor.openMonitor()
    }
  }, [room])

  const onRoomError = useCallback((error) => {
    if (error) {
      (error.code === 53205) && history.replace({
        pathname: 'error',
        state: { errorType: errorTypes.ALREADY_CONNECTED }
      });

      (error.code === 20104) && history.replace({
        pathname: 'error',
        state: { errorType: errorTypes.TIME_UP }
      });

      (error.code === 53000 || error.code === 53204) && history.replace({
        pathname: 'error',
        state: { errorType: errorTypes.CONNECTION }
      })
    }

    if (error?.code === 53001) {
      const disconnectData = {
        room: meeting.schedule.hash,
        value: new Date().toISOString(),
      }

      localStorage.setItem('@telavita-meet/disconnectData', JSON.stringify(disconnectData))
    }

    Sentry.captureException(error)
    setLocalMessages('Conexão perdida. Você será redirecionado para a sala de espera.')
    setTimeout(() => {
      updateView(views.waitingRoom)
    }, 3000)
  }, [history])

  const onParticipantConnect = useCallback((participant) => {
    console.log('participante remoto conectou:', participant)
    setParticipant(participant)
    setParticipantLeft(false)
    setParticipantReconnection(false)
    setParticipantDisconnection(false)
    setCanHideElementsDesktop(true)
    setToggleDisplay(false)

    const participantHasAudioTracks = participant.audioTracks.size > 0
    const participantHasVideoTracks = participant.videoTracks.size > 0

    if (!participantHasAudioTracks) {
      setShowRemoteAudio(false)
      setUnsubscribedRemoteAudioTrack(null)
      setRemoteAudioTrack(null)
    }

    if (!participantHasVideoTracks) {
      setShowRemoteVideo(false)
      setUnsubscribedRemoteVideoTrack(null)
      setRemoteVideoTrack(null)
    }
  }, [])

  const onParticipantDisconnect = useCallback(() => {
    setParticipantLeft(true)
    setShowRemoteAudio(false)
    setShowRemoteVideo(false)
    setParticipant(null)
    setParticipantDisconnection(true)
    setCanHideElementsDesktop(false)
    setToggleDisplay(true)
  }, [])

  const onParticipantReconnecting = useCallback(() => {
    setParticipantReconnection(true)
    setLocalMessages('O participante está tentando reconectar.')
  }, [])

  const onParticipantReconnected = useCallback(() => {
    setParticipantReconnection(false)
    participant.tracks.forEach(track => {
      if (track.kind === 'audio') setShowRemoteAudio(track.isTrackEnabled)
      if (track.kind === 'video') setShowRemoteVideo(track.isTrackEnabled)
    })
  }, [participant])

  const onParticipantNetworkQualityLevelChanged = useCallback((networkQualityLevel) => {
    setParticipantNetwork(networkQualityLevel)
  }, [])

  const onParticipantTrackSubscribed = useCallback(track => {
    console.log('nova track remota: ', track)
    const { guest } = meeting

    if (track.kind === 'data') {
      track.on('message', data => {
        setChatMessage({
          text: data,
          author: guest.first_name
        })
      })
    } else {
      if (track.kind === 'video') {
        if (participantLeft) setParticipantLeft(false)
        const el = remoteVideoRef.current
        track.attach(el)
        setRemoteVideoTrack(track)
        if (!track.isEnabled) setShowRemoteVideo(false)
        else setShowRemoteVideo(true)
      }

      if (track.kind === 'audio') {
        track.attach(remoteAudioRef.current)
        setRemoteAudioTrack(track)
        if (!track.isEnabled) setShowRemoteAudio(false)
        else setShowRemoteAudio(true)
      }
    }
  }, [remoteAudioRef, remoteVideoRef])

  const onParticipantTrackUnsubscribed = useCallback(track => {
    console.log('saiu track remota: ', track)

    if (track.kind === 'audio') {
      track.detach(remoteAudioRef.current)
      setUnsubscribedRemoteAudioTrack(track)
    }
    if (track.kind === 'video') {
      track.detach(remoteVideoRef.current)
      setUnsubscribedRemoteVideoTrack(track)
    }
  }, [])

  const onParticipantTrackEnabled = useCallback(track => {
    if (track.kind === 'audio') setShowRemoteAudio(true)
    if (track.kind === 'video') setShowRemoteVideo(true)
  }, [])

  const onParticipantTrackDisabled = useCallback(track => {
    if (track.kind === 'audio') setShowRemoteAudio(false)
    else if (track.kind === 'video') setShowRemoteVideo(false)
    else return null
  }, [])

  const onLocalReconnecting = useCallback(() => {
    fetchApi.sendLogToServer(meeting.schedule.hash, {
      event: 'GUEST_RECONNECTING'
    })

    setLocalMessages('Estamos tentando te reconectar. Aguarde.')
  }, [])

  const onLocalReconnected = useCallback(() => {
    setLocalMessages('')
  }, [])

  const onLocalDisconnected = useCallback((room, error) => {
    room.localParticipant.tracks.forEach(pub => {
      pub.unpublish()
    })

    onRoomError(error)
  }, [])

  const onLocalNetworkQualityLevelChanged = useCallback(throttle((networkQualityLevel) => {
    setLocalNetwork(networkQualityLevel)
    fetchApi.sendLogToServer(meeting.schedule.hash, {
      event: 'GUEST_QUALITY_LEVEL',
      value: networkQualityLevel.toString(),
    })
  }, 5000), [])

  const onLocalTrackSwitchedOff = useCallback(track => {
    track.on('disabled', () =>
      setLocalMessages('Sinal de internet está fraco.\nPara que a consulta possa continuar, desativamos o vídeo e mantivemos apenas o áudio.\nO vídeo retornará assim que a internet estiver estável.')
    )
  }, [])

  useEffect(() => {
    if (unsubscribedRemoteAudioTrack && remoteAudioTrack) {
      if (unsubscribedRemoteAudioTrack.name === remoteAudioTrack.name) {
        setShowRemoteAudio(false)
        setUnsubscribedRemoteAudioTrack(null)
        setRemoteAudioTrack(null)
      }
    }
  }, [unsubscribedRemoteAudioTrack, remoteAudioTrack])

  useEffect(() => {
    if (unsubscribedRemoteVideoTrack && remoteVideoTrack) {
      if (unsubscribedRemoteVideoTrack.name === remoteVideoTrack.name) {
        setShowRemoteVideo(false)
        setUnsubscribedRemoteVideoTrack(null)
        setRemoteVideoTrack(null)
      }
    }
  }, [unsubscribedRemoteVideoTrack, remoteVideoTrack])

  // Eventos da sala local
  useEffect(() => {
    if (room) {
      room.on('participantConnected', onParticipantConnect)
      room.on('participantDisconnected', onParticipantDisconnect)
      room.participants.forEach(onParticipantConnect)

      room.on('reconnecting', onLocalReconnecting)
      room.on('reconnected', onLocalReconnected)
      room.on('disconnected', onLocalDisconnected)
      room.on('trackSwitchedOff', onLocalTrackSwitchedOff)
      room.localParticipant.on('networkQualityLevelChanged', onLocalNetworkQualityLevelChanged)
    }
  }, [room])

  // Eventos do participante remoto
  useEffect(() => {
    if (participant) {
      participant.on('trackSubscribed', onParticipantTrackSubscribed)
      participant.on('trackUnsubscribed', onParticipantTrackUnsubscribed)
      participant.on('trackEnabled', onParticipantTrackEnabled)
      participant.on('trackDisabled', onParticipantTrackDisabled)
      participant.on('networkQualityLevelChanged', onParticipantNetworkQualityLevelChanged)
      participant.on('reconnecting', onParticipantReconnecting)
      participant.on('reconnected', onParticipantReconnected)
    }
  }, [participant])

  useEffect(() => {
    if (participantReconnection) return

    const textMessage = {
      participantDisconnected: 'Participante saiu da sala',
      participantExpected: 'Aguardando participante entrar',
      participantAllMediasOn: '',
      participantAllMediasOff: 'O participante desligou a câmera e o microfone',
      participantCameraOff: 'O participante desligou a câmera',
      participantMicrophoneOff: 'O participante desligou o microfone'
    }
    if (!participant) {
      setLocalMessages(participantDisconnection ? textMessage.participantDisconnected : textMessage.participantExpected)
    } else {
      if (!showRemoteVideo && !showRemoteAudio) setLocalMessages(textMessage.participantAllMediasOff)
      else if (!showRemoteVideo) setLocalMessages(textMessage.participantCameraOff)
      else setLocalMessages(textMessage.participantAllMediasOn)
    }
  }, [showRemoteAudio, showRemoteVideo, participant, participantDisconnection, participantReconnection])

  const handleDisplayMobile = e => {
    e.preventDefault()
    if (canHideElementsMobile) setToggleDisplay(prevDisplay => !prevDisplay)
  }

  const handleMouseOver = e => {
    e.preventDefault()
    clearTimeout(timer)

    if (canHideElementsDesktop) {
      setMouseOver(true)
    }
  }

  const handleMouseLeave = e => {
    e.preventDefault()

    if (canHideElementsDesktop) {
      setMouseOver(false)
    }
  }

  const showDisplay = throttle(() => {
    setToggleDisplay(true)
  }, 1500, {
    leading: false,
    trailing: true,
  })

  const handleMouseMove = e => {
    e.preventDefault()
    clearTimeout(timer)

    if (!toggleDisplay) {
      showDisplay()
    } else {
      timer = setTimeout(() => {
        if (!mouseOver) {
          setToggleDisplay(false)
        }
      }, 3000)
    }

  }

  const handleChatBox = () => {
    setIsChatOpen(isChatOpen ? false : true)
  }

  const onSendMessage = useCallback(message => {
    const { user } = meeting

    setChatMessage({
      text: message,
      author: user ? user.first_name : 'você'
    })

    if (localDataTrack) {
      localDataTrack.send(message)
    }
  }, [localDataTrack, meeting])

  const handleLeaveCall = useCallback(() => {
    setShowGuestEndCallModal(state => !state)
  }, [showGuestEndCallModal])

  const handleStartInterval = () => {
    setCanHideElementsDesktop(false)
    setCanHideElementsMobile(false)
    setToggleDisplay(true)
    if (meeting.schedule.interval === 0) handleExit()
    else setStartInterval(true)
  }

  const handleOpenSettings = () => {
    if (mediaErrorMessage) {
      return toast({
        text: 'Você deve permitir o acesso a câmera e microfone para acessar as configurações',
        type: 'error',
        autoClose: 5000,
        position: 'top-right'
      })
    }
    
    onOpenSettingsModal()
  }

  const handleExit = () => {
    const { guest, schedule } = meeting
    room.disconnect()

    const scheduleId = schedule?.id
    const friendlyUrl = guest?.friendly_url
    const professionalProfileCode = professional.profile_code

    window.location.assign(externalRoutes.SURVEY(scheduleId, friendlyUrl, professionalProfileCode))
  }

  const isUserProfessional = () => {
    const { user: { username } } = meeting
    return username === professional?.username
  }

  useEffect(() => {
    if (activeSpeakerDevice && remoteAudioRef && remoteAudioRef.current.setSinkId) {
      remoteAudioRef.current.setSinkId(activeSpeakerDevice.deviceId)
    }
  }, [activeSpeakerDevice, remoteAudioRef])

  useEffect(() => {
    if (room && userAudioTrack) {
      console.log('publicando nova track de áudio', userAudioTrack)

      room.localParticipant.publishTrack(userAudioTrack).then(newPublication => {
        room.localParticipant.audioTracks.forEach(publication => {
          if (publication.trackSid !== newPublication.trackSid) {
            console.log('despublicando track de áudio anterior', publication.track)
            publication.unpublish()
          }
        })
      })
    } else if (room && !userAudioTrack) {
      console.log('track de áudio desabilitada:', userAudioTrack)
      room.localParticipant.audioTracks.forEach(publication => publication.unpublish())
    }
  }, [room, userAudioTrack])

  useEffect(() => {
    if (room && localVideoRef && userVideoTrack) {
      console.log('publicando nova track de vídeo', userVideoTrack)

      const ms = new MediaStream()
      ms.addTrack(userVideoTrack)
      localVideoRef.current.srcObject = ms

      room.localParticipant.publishTrack(userVideoTrack).then(newPublication => {
        room.localParticipant.videoTracks.forEach(publication => {
          if (publication.trackSid !== newPublication.trackSid) {
            console.log('despublicando track de vídeo anterior', publication.track)
            publication.unpublish()
          }
        })
      })
    } else if (room && !userVideoTrack) {
      console.log('track de vídeo desabilitada:', userVideoTrack)
      room.localParticipant.videoTracks.forEach(publication => publication.unpublish())
    }
  }, [userVideoTrack, room])

  useEffect(() => {
    if (!localDataTrack && room) {
      const dataTrack = new LocalDataTrack()
      console.log('publicando nova track de dados', dataTrack)

      setLocalDataTrack(dataTrack)
      room.localParticipant.publishTrack(dataTrack)
    }
  }, [localDataTrack, room])

  return (
    <div className='CallRoom'>
      {
        showGuestEndCallModal && <GuestEndCallModal handleClick={handleLeaveCall} handleExit={handleExit} />
      }

      <PatientDetails
        open={patientDetailsOpen}
        setOpen={setPatientDetailsOpen}
        patientData={patientData}
      />

      <Reservation
        open={reservationOpen}
        setOpen={setReservationOpen}
        professional={meeting.user}
        patient={meeting.guest}
        patientPlanCode={meeting.schedule.planCode}
        refetchReservation={refetchReservation}
        isPsychologist={isPsychologist}
      />

      {reservation && (
        <RescheduleReservation
          reservation={reservation}
          onRefresh={refetchReservation}
          handleClose={() => setRescheduleReservationOpen(false)}
          open={rescheduleReservationOpen}
        />
      )}

      <ChatContainer
        isChatOpen={isChatOpen}
        chatMessage={chatMessage}
        handleToggleChat={handleChatBox}
        handleSendMessage={m => onSendMessage(m)}
      />

      <div className='CallRoom__components'>
        <div className='CallRoom__components__header'>
          <div className='CallRoom__components__header--left'
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
          >
            <CallHeader startInterval={() => handleStartInterval()} showElement={toggleDisplay} meeting={meeting} />
            {
              startInterval &&
              <CallHeaderAlert onExit={() => handleExit()} interval={meeting.schedule ? meeting.schedule.interval : 10} />
            }
          </div>

          <div className='CallRoom__components__header--right'>
            {
              !showRemoteAudio &&
              <div className='Participant__mic-off'>
                <CustomIcons icon={iconList.MicrophoneOff} fill={colors.WHITE} width='14.97px' height='15.8px' />
              </div>
            }

            <NetworkSignal networkSignal={participantNetwork} />
          </div>
        </div>

        <div
          className='CallRoom__components__main'
          onTouchEnd={e => handleDisplayMobile(e)}
          onMouseMove={e => handleMouseMove(e)}
        >
          {localMessages && (
            <FeedbackCallMessageBox>{localMessages}</FeedbackCallMessageBox>
          )}

          <div className='LocalVideoContainer'>
            {
              !videoEnabled ? <div className='LocalVideoContainer__icon'>
                <CustomIcons icon={iconList.CameraOff} fill={colors.WHITE} />
              </div> : null
            }

            <NetworkSignal networkSignal={localNetwork} />
            <video
              ref={localVideoRef}
              autoPlay
              playsInline
              muted
            />
          </div>
        </div>

        <div className='CallRoom__components__footer' onMouseOver={e => handleMouseOver(e)} onMouseLeave={e => handleMouseLeave(e)}>
          <CallControllers
            showElement={toggleDisplay}
            toggleChatBox={handleChatBox}
            toggleModal={handleLeaveCall}
            isProfessional={isUserProfessional()}
            onSettingsClick={handleOpenSettings}
            onReservationClick={() => setReservationOpen(b => !b)}
            onRescheduleReservationClick={() => setRescheduleReservationOpen(prevState => !prevState)}
            isAudioEnabled={audioEnabled}
            isVideoEnabled={videoEnabled}
            onToggleAudio={handleToggleAudio}
            onToggleVideo={handleToggleVideo}
            showReservationOption={isPsychologist}
            showRescheduleReservationOption={isPsychologist && !!reservation}
            onPatientDetailsClick={() => setPatientDetailsOpen(oldState => !oldState)}
          />
        </div>
      </div>

      <div className={`MainVideoContainer ${showRemoteVideo ? 'videoOn' : 'videoOff'}`}>
        {!participantLeft &&
          <video
            ref={remoteVideoRef}
            playsInline
            autoPlay
            muted
          />
        }
        <audio ref={remoteAudioRef} />
      </div>
    </div>
  )
}

export default CallRoom

import { useMutation, useQuery } from '@tanstack/react-query'

import { axios, endpoints } from '~/settings'

const getMedicalRecord = async (patientUUID) => {
  const response = await axios.get(endpoints.medicalRecord.SEARCH_MEDICAL_RECORD(patientUUID))
  
  return response.data
}

const createMedicalRecord = async (patientUUID) => {
  const response = await axios.post(endpoints.medicalRecord.CREATE_MEDICAL_RECORD, { patient_uuid: patientUUID })
  
  return response.data
}

export const useMedicalRecord = ({ guest }, isProfessional) => {
  const patientUUID = guest?.user_uuid

  const { mutate: postMedicalRecord, data: medicalRecordCreatedData, isSuccess: medicalRecordCreatedSuccess } = useMutation({
    mutationKey: ['create_medical_record'],
    mutationFn: () => createMedicalRecord(patientUUID),
  })

  const { data: medicalRecordData } = useQuery({
    queryKey: ['fetch_medical_record'],
    queryFn: () => getMedicalRecord(patientUUID),
    cacheTime: 0,
    enabled: isProfessional,
    retry: false,
    refetchOnWindowFocus: false,
    onError: () => postMedicalRecord(),
  })

  if (medicalRecordCreatedSuccess) {
    return {
      medicalRecord: {
        uuid: medicalRecordCreatedData?.data.uuid,
        forms: [],
      }
    }
  }

  return { medicalRecord: medicalRecordData?.data.medical_record }
}
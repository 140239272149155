import styled from 'styled-components'

import { colors } from '~/utils/stylesConstants'

export const SuccessReservationContainer = styled.div`
  width: 340px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;

  button {
    width: 100%;
  }
`

export const Box = styled.div`
  background: ${colors.YELLOW};

  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  span {
    color: ${colors.RED_01};
    font-weight: bold;
  }
`

export const SuccessMessageTime = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
`
import { useMutation } from '@tanstack/react-query'

import { axios, endpoints } from '~/settings'
 
async function handleUpdateReservation({
  uuid,
  nonRecurrentReservationDates,
  professionalUsername,
  patientUsername,
  recurrentStartDate,
  recurrentEndDate
}){
  const payload = {
    professional_username: professionalUsername,
    patient_username: patientUsername,
    ...(recurrentStartDate && { recurrent_start_date: recurrentStartDate }),
    ...(recurrentEndDate && { recurrent_end_date: recurrentEndDate }),
    ...(nonRecurrentReservationDates.length && {
      non_recurrent_reservation_dates: nonRecurrentReservationDates.map(item => ({
        old_date: item.oldDate,
        new_date: item.newDate
      }))
    })
  }

  return await axios.patch(`${String(endpoints.reservations.RESERVATION)}${uuid}/`, payload)
}

export const useUpdateReservation = () => {
  return useMutation(handleUpdateReservation)
}
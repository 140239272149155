import React from 'react'

import PropTypes from 'prop-types'

const Send = ({ fill = '#000', height = '24', width = '24' }) =>
  <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 24 24" width={width}>
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z" fill={fill} />
  </svg>

Send.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string
}

export default Send

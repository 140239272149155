import React, { useEffect, useState } from 'react'

import { Drawer, Spinner, Typography } from '@telavita-core/react-design-kit'

import NewReservation from './NewReservation'
import Restrictions from './Restrictions/Restrictions'

import { useGetPatientRestrictions } from '~/hooks'

const Reservation = ({
  open,
  setOpen,
  professional,
  patient,
  patientPlanCode,
  refetchReservation,
  isPsychologist
}) => {
  const [success, setSuccess] = useState(false)
  const { full_name: patientName, username: patientUsername } = patient

  const {
    restrictions,
    setRestrictions,
    refetchRestrictions,
    patientScheduleList
  } = useGetPatientRestrictions({
    patientUsername,
    patientPlanCode,
    isPsychologist,
    isReservationOpen: open,
  })

  const handleRefetchDataForReservation = () => {
    refetchReservation()
    refetchRestrictions({
      patientUsername: patientUsername,
      patientPlanCode: patientPlanCode,
    })
  }

  useEffect(() => {
    setSuccess(false)
  }, [restrictions])

  const handleContinueWithReservation = () => {
    setRestrictions({ status: 'OK' })
  }

  const handleCloseDrawerOnSuccess = () => {
    handleRefetchDataForReservation()
    setOpen(false)
  }

  const renderRestriction = ({
    message,
    showIcon,
  }) => (
    <Restrictions
      message={message}
      patientFirstName={patient.first_name}
      handleContinueWithReservation={handleContinueWithReservation}
      showIcon={showIcon}
      totalSchedules={patientScheduleList.length}
    />
  )

  const renderContent = () => {
    if (!restrictions) {
      return <Spinner customClassName='Reservation__spinner__fullScreen' />
    }

    const { status, message } = restrictions

    const renderMap = {
      ERROR: () => renderRestriction({ message }),
      PATIENT_ALREADY_HAS_SCHEDULE: () => renderRestriction({ message, showIcon: false }),
      ONLY_PARTICULAR: () => renderRestriction({ message }),
      OK: () =>
        <NewReservation
          patient={patient}
          professional={professional}
          success={success}
          setSuccess={setSuccess}
          patientPlanCode={patientPlanCode}
          handleCloseDrawerOnSuccess={handleCloseDrawerOnSuccess}
        />
    }

    return renderMap[status] ? renderMap[status]() : null
  }

  return (
    <Drawer
      open={open}
      setOpen={
        success ? () => handleCloseDrawerOnSuccess() : setOpen
      }
      anchor='right'
      customClassName='Reservation__drawer'
    >
      <div className='Reservation'>
        <div className='Reservation__container'>
          {!success && (
            <div className='Reservation__container__wrapper'>
              <Typography variant='header4'>
                Reserva de horário
              </Typography>
              <Typography variant='content2' inheritColor>
                Paciente <b>{patientName}</b>
              </Typography>
            </div>
          )}
          {renderContent()}
        </div>

      </div>
    </Drawer>
  )
}

export default Reservation
import React from 'react'

import PropTypes from 'prop-types'

export const Button = ({
  children = 'label',
  classModifier,
  id = 'link',
  isDisabled = false,
  type = 'button',
  onButtonClick
}) => {
  return (
    <button id={`btn-${id}`}
      className={`Button${classModifier ? '--' + classModifier : ''}`}
      disabled={isDisabled}
      type={type}
      onClick={onButtonClick}>
      {children}
    </button>
  )
}

Button.propTypes = {
  children: PropTypes.any,
  classModifier: PropTypes.oneOf([
    'reverse'
  ]),
  id: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  type: PropTypes.string,
  onButtonClick: PropTypes.func
}
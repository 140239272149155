import { useState } from 'react'

import { axios, endpoints } from '~/settings'

const KIPP_PLAN_GROUP_CODE = 'KIPPSAUD'
const OMINT_PLAN_GROUP_CODE = 'GPL01008'
const SAMI_PLAN_GROUP_CODE = 'SAMISAUD'

export const planGroupsUsesScheduleToken = {
  KIPP_PLAN_GROUP_CODE,
  OMINT_PLAN_GROUP_CODE,
  SAMI_PLAN_GROUP_CODE,
}

export function getInstructionsToken(planGroupCode, planGroupName) {
  if ([KIPP_PLAN_GROUP_CODE, OMINT_PLAN_GROUP_CODE].includes(planGroupCode)) {
    return {
      first: {
        textLineTwo: 'Credencial.',
        textLineOne: `Entre no app da ${planGroupName} e vá em`,
      },
      second: `O token ${planGroupName} está localizado abaixo das informações de sua carteirinha.`,
    }
  }
  if (planGroupCode === SAMI_PLAN_GROUP_CODE) {
    return {
      first: {
        textLineTwo: 'Carteirinha e Token.',
        textLineOne: `Entre no app da ${planGroupName} e vá em`,
      },
      second: 'Acesse a aba “token” para visualizar os números.',
    }
  }
}

export const VALIDATE_TOKEN_ERRORS_MESSAGE = {
  503: 'No momento, seu convênio encontra-se indisponível para verificação do token. Tente novamente.',
  424: 'Código inválido. Verifique novamente o token.',
  400: 'Código inválido. Verifique novamente o token.',
}

export function useValidateToken() {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isRedirecting, setIsRedirecting] = useState(false)
  const [errorStatusCode, setErrorStatusCode] = useState(null)

  async function onSubmit(scheduleId, tokenValue) {
    setIsSubmitting(true)
    try {
      await axios.post(endpoints.schedules.TOKEN_VALIDATION(scheduleId), { token: tokenValue.trim() })
      setIsRedirecting(true)
      return {
        isValid: true
      }
    } catch (error) {
      setErrorStatusCode(error.status)
      setIsSubmitting(false)
      return {
        isValid: false
      }
    }
  }

  return {
    isSubmitting,
    isRedirecting,
    errorStatusCode,
    onSubmit,
  }
}
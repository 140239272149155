import React, { useState } from 'react'

import { Button, Spinner, TextInput, Typography, useDimensions } from '@telavita-core/react-design-kit'

import BaseModal from '../BaseModal'
import CustomIcons, { iconList } from '../CustomIcons'

import { useValidateToken, VALIDATE_TOKEN_ERRORS_MESSAGE, getInstructionsToken, planGroupsUsesScheduleToken } from '~/hooks'
import { ReactComponent as IconToken } from '~/static/svg/icon_token.svg'

const RequestTokenModal = ({ onClose, planGroupName, onRedirect, scheduleId, planGroupCode }) => {
  const { isMobile } = useDimensions()

  const { errorStatusCode, isRedirecting, isSubmitting, onSubmit } = useValidateToken()

  const [tokenValue, setTokenValue] = useState('')

  const isDisabledSubmitButton = tokenValue.length < 1 || isSubmitting

  const instructions = getInstructionsToken(planGroupCode, planGroupName)

  function handleChangeTokenValue(event) {
    setTokenValue(event.target.value)
  }

  async function handleSubmit() {
    const { isValid } = await onSubmit(scheduleId, tokenValue)

    if (!isValid) return

    setTimeout(() => {
      onRedirect()
    }, 3000)
  }

  return (
    <BaseModal>
      <div className='RequestTokenModal__container'>
        {isRedirecting ? (
          <>
            <div className='RequestTokenModal__container__spinnerWrapper'>
              <Spinner />
            </div>

            <Typography
              variant='header4'
              center
              weight='bold'
              customClassName='RequestTokenModal__container__redirectText'
            >
              Token enviado com sucesso. Redirecionando para a consulta.
            </Typography>
          </>
        ) : (
          <>
            <h2 className='RequestTokenModal__container__title'>
              Para realizar a sua consulta, é preciso inserir o token do seu aplicativo {planGroupName}.
            </h2>  
            
            <button className='RequestTokenModal__container__closeButton' onClick={onClose}>
              <CustomIcons
                icon={iconList.X}
              />
            </button>

            <IconToken />

            <div className='RequestTokenModal__container__tokenInputWrapper'>
              <TextInput
                name='token'
                placeholder='Digite o código*'
                onInputChange={handleChangeTokenValue}
                type={isMobile ? 'number' : 'text'}
                hasError={Boolean(errorStatusCode)}
                messageError={VALIDATE_TOKEN_ERRORS_MESSAGE[errorStatusCode]}
                disabled={isSubmitting}
              />
            </div>

            <Typography
              italic
              variant='content3'
              customClassName='RequestTokenModal__container__message'
            >
              *Preenchimento obrigatório
            </Typography>

            <Button
              disabled={isDisabledSubmitButton}
              variant='contained'
              customClassName='RequestTokenModal__container__submitButton'
              onClick={handleSubmit}
            >
              {isSubmitting ? <Spinner /> : 'Enviar token'}
            </Button>

            <hr className='RequestTokenModal__container__separator' />

            <div className='RequestTokenModal__container__tokenInfoWrapper'>
              <Typography weight='bold'>
                Como localizar o token?
              </Typography>
              <ul>
                <li>
                  <div>
                    <span>1</span>
                  </div>
                  <Typography variant='content2'>
                    {instructions?.first?.textLineOne}
                    <br />
                    <Typography
                      variant='content2'
                      weight='bold'
                      italic={planGroupCode === planGroupsUsesScheduleToken.SAMI_PLAN_GROUP_CODE}
                    >
                      {instructions?.first?.textLineTwo}
                    </Typography>
                  </Typography>
                </li>
                <li>
                  <div>
                    <span>2</span>
                  </div>
                  <Typography variant='content2'>
                    {instructions?.second}
                  </Typography>
                </li>
              </ul>
            </div>
          </>
        )}
      </div>
    </BaseModal>
  )
}

export default RequestTokenModal

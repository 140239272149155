import React from 'react'

import PropTypes from 'prop-types'

const CameraOff = ({ fill = '#000', height = '24', width = '24' }) =>
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 24 24'>
    <path d='M0,0H24V24H0ZM0,0H24V24H0Z' fill='none' />
    <path d='M21,6.5l-4,4V7a1,1,0,0,0-1-1H9.82L21,17.18ZM3.27,2,2,3.27,4.73,6H4A1,1,0,0,0,3,7V17a1,1,0,0,0,1,1H16a.969.969,0,0,0,.54-.18L19.73,21,21,19.73Z' fill={fill} />
  </svg>

CameraOff.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string
}

export default CameraOff

import React from 'react'

const FeedbackCallMessageBox = ({ children }) => {
  return (
    <div className='FeedbackCallMessageBox'>
      <p>{children}</p>
    </div>
  )
}

export default FeedbackCallMessageBox

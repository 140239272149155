import { useQuery } from '@tanstack/react-query'

import { axios, endpoints } from '~/settings'

const getReservation = async ({ professionalUsername }) => {
  const response = await axios.get(`${endpoints.reservations.RESERVATION}${professionalUsername}/`)
  
  return response.data
}

export const useGetReservation = ({ professionalUsername, patientUsername }) => {
  const { data, isSuccess, refetch } = useQuery({
    queryKey: ['reservation', professionalUsername],
    queryFn: () => getReservation({ professionalUsername, patientUsername }),
    cacheTime: 0,
    enabled: !!professionalUsername,
    retry: false,
    refetchOnWindowFocus: false,
  })

  if (isSuccess) {
    const reservation = data.data.reservations.find((reservation) => {
      return reservation.patient_username === patientUsername && reservation.recurrence === 'Semanal'
    })
    return { reservation, refetch }
  }

  return { reservation: null, refetch }
}
import React from 'react'

import { format } from 'date-fns'

import { Button, Typography } from '@telavita-core/react-design-kit'

const handleRestrictionMessage = (restrictions) => {
  if (restrictions.length === 1) {
    return 'Defina outro horário para este dia'
  }

  return 'Defina outros horários para estes dias'
}

export const UnavailabilityInfo = ({ restrictions, handleShowAvailabilityOptions, alreadyHasData }) => (
  <>
    <Typography variant='content2'>
      Informe ao paciente que você está indisponível em:
    </Typography>
    
    {restrictions.map(restriction => (
      <Typography key={restriction} variant='content2' weight='bolder'>
        {format(new Date(restriction), 'dd/MM/yyyy')}
      </Typography>
    ))}

    <Button 
      variant='text' 
      uppercase={false} 
      onClick={() => {
        alreadyHasData
          ? null
          : handleShowAvailabilityOptions()
      }}
    >
      {handleRestrictionMessage(restrictions)}
    </Button>
  </>
)
import React from 'react'

import { Button, Paper, Typography } from '@telavita-core/react-design-kit'

import * as S from './styles'

export const OnlyParticularReservation = ({ handleContinueWithReservation }) => {
  return (
    <S.OnlyParticularReservationWrapper>
      <Paper variant='warning'>
        <Typography variant='content2' weight='bolder'> ATENÇÃO: </Typography>

        <Typography variant='content2'>
          A reserva é semanal, mas o convênio do paciente não garante agendamentos semanais.
        </Typography>

        <Typography variant='content2'>
          Considere que: <br />
          1) Nas semanas não cobertas pelo convênio, o paciente pode se comprometer a agendar pelo particular.
        </Typography>

        <Typography variant='content2'>
          ou
        </Typography>

        <Typography variant='content2'>
          2) Nas semanas não cobertas pelo convênio, a reserva será perdida.
        </Typography>

        <Typography variant='content2'>
          Deseja continuar mesmo assim?
        </Typography>
      </Paper>
      <Button onClick={handleContinueWithReservation} variant='contained'>Sim, continuar</Button>
    </S.OnlyParticularReservationWrapper>
  )
}
import React, { createContext, useState } from 'react'

export const ScheduleContext = createContext()

const PATIENT_PROFILE_CODE = 'TVTVPACI'
const PSIC_PROFILE_CODE = 'TVTVPSIC'

function getPatientData(data) {
  const patient = data.schedule?.guests.find(guest => guest.profile_code === PATIENT_PROFILE_CODE)
  
  return {
    username: patient?.username,
  }
}

function getProfessionalData(data) {
  const professional = data.schedule?.guests.find(guest => guest.profile_code === PSIC_PROFILE_CODE)

  return {
    username: professional?.username,
  }
}

export const ScheduleProvider = ({ children }) => {
  const [schedule, setSchedule] = useState({})
  const patientData = getPatientData(schedule)
  const professionalData = getProfessionalData(schedule)
  
  const value = {
    setSchedule,
    patientData,
    professionalData
  }

  return (
    <ScheduleContext.Provider value={value}>
      {children}
    </ScheduleContext.Provider>
  )
}
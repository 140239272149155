import React, { useState } from 'react'

import PropTypes from 'prop-types'

import { format } from 'date-fns'

import { Button, Typography, Popover } from '@telavita-core/react-design-kit'

import * as S from './styles'

import { ReactComponent as ArrowDownIcon } from '~/static/svg/icon_arrow-down.svg'
import { ReactComponent as ArrowUpIcon } from '~/static/svg/icon_arrow-up.svg'

import { WeeklyCalendar } from '..'

const formatDate = (date) => {
  if (!date) return null
  
  return format(date, 'dd/MM')
}

const ArrowIcon = ({ isOpen }) => isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />

ArrowIcon.propTypes = {
  isOpen: PropTypes.bool.isRequired,
}

export const SelectAvailabilityDate = ({ selectedDate, setSelectedDate }) => {
  const [openWeeklyCalendar, setOpenWeeklyCalendar] = useState(false)
  
  const closeShortCalendar = () => {
    setOpenWeeklyCalendar(false)
  }

  const handleDateChange = (date) => {
    setSelectedDate(date)
    closeShortCalendar()
  }

  const toggleWeeklyCalendar = () => {
    if (selectedDate) {
      setOpenWeeklyCalendar((isOpen) => !isOpen)
    }
  }

  const content = (
    <WeeklyCalendar
      initialDate={selectedDate}
      handleDateChange={handleDateChange}
    />
  )

  return (
    <S.PopoverWrapper>
      <Popover
        content={content}
        onClickOutside={closeShortCalendar}
        visible={openWeeklyCalendar}
        placement='bottom-start'
      >
        <S.Wrapper isWeeklyCalendarOpen={openWeeklyCalendar}>
          <Button onClick={toggleWeeklyCalendar}>
            <Typography 
              variant='content2' 
              weight='bold' 
              customClassName='DatePickerText' 
            >
              {formatDate(selectedDate) || '--/--'}
            </Typography>
            <ArrowIcon isOpen={openWeeklyCalendar} />
          </Button>
        </S.Wrapper>
      </Popover>
    </S.PopoverWrapper>
  )
}

SelectAvailabilityDate.propTypes = {
  selectedDate: PropTypes.instanceOf(Date).isRequired,
  setSelectedDate: PropTypes.func.isRequired,
}
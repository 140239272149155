import React from 'react'

import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

import { Typography } from '@telavita-core/react-design-kit'

import { colors } from '~/utils/stylesConstants'

const NO_AVAILABILITY_OPTION = {
  id: 'no-availability',
  label: (
    <Typography variant='content2' italic inlineStyles={{ color: colors.GREY_06 }}>
      Nenhum horário. <br />
      Mude a data no <br />
      campo ao lado.
    </Typography>
  ),
}

export const getHoursAndMinutes = date => format(date, 'HH:mm')

export const formatSelectOptions = availabilityList => {
  if (availabilityList.length === 0) return [NO_AVAILABILITY_OPTION]

  return availabilityList?.map(date => {
    const dateObject = new Date(date)
    
    return {
      id: dateObject,
      label: getHoursAndMinutes(dateObject),
    }
  })
}

export const formatDateForRestriction = date => {
  if (!date) return null
  return utcToZonedTime(new Date(date), 'Etc/UTC')
}

export const formatDateTime = (selectedDate, selectedHour) => {
  if (!selectedDate || !selectedHour) return null

  const formattedHour = selectedHour && format(new Date(selectedHour.id), 'HH:mm:ss')
  const formattedDate = format(selectedDate, 'yyyy-MM-dd')
  return `${formattedDate}T${formattedHour}`
}

export const handleSelectHour = hour => {
  if (hour.id === NO_AVAILABILITY_OPTION.id) return null
  return hour
}

import React, { useEffect, useState } from 'react'
import Timer from 'react-compound-timer'

import CustomIcons, { iconList } from '~/components/CustomIcons'

import { dateTimeHandler } from '~/handlers'
import { colors } from '~/utils/stylesConstants'

const CallHeader = ({ startInterval, showElement, meeting }) => {
  const [startTimer, setStartTimer] = useState(0)
  const [timeUp, setTimeUp] = useState(false)
  const [guestName, setGuestName] = useState('Participante')

  useEffect(() => {
    // console.log('START TIMER')
    const { schedule, guest } = meeting
    if (guest) setGuestName(guest.full_name)

    if (schedule) {
      const remainingDuration = dateTimeHandler.getDurationInMs(null, schedule.endDate) + schedule.diffDuration
      setStartTimer(remainingDuration)
    }
  }, [meeting])

  return (
    <div className={`CallHeader${showElement ? '' : '--hide'}`}>
      <div className='CallHeader__guest-name'>
        <span>Consulta com</span>
        <p>{guestName}</p>
      </div>

      <div className='CallHeader__timerbox'>
        <CustomIcons icon={iconList.Clock} fill={timeUp ? colors.RED_01 : colors.GREY_04} />
        {
          timeUp &&
          <div className='CallHeader__timerbox__timeUp'>
            <p>Tempo acabou</p>
          </div>
        }

        <div className='CallHeader__timerbox__timer'>
          {
            startTimer !== 0 && !timeUp &&
            <Timer
              direction="backward"
              formatValue={value => `${(value < 10 ? `0${value}` : value)}`}
              initialTime={startTimer}>
              {({ setCheckpoints, stop }) => {
                setCheckpoints([
                  {
                    time: 0,
                    callback: () => {
                      stop()
                      setTimeUp(true)
                      startInterval()
                    },
                  },
                ])
                return (
                  <div>
                    Restam <Timer.Minutes /><span className="CallHeader__timerbox__timer__seconds">:<Timer.Seconds /></span>
                  </div>
                )
              }}
            </Timer>
          }
        </div>
      </div>
    </div>
  )
}

export default CallHeader

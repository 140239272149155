import React from 'react'

import PropTypes from 'prop-types'

const Sofa = ({ fill = '#000', height = '38.05', width = '38.128' }) =>
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 38.128 38.05'>
    <path d='M37.969,14.556l0-.008A5.667,5.667,0,0,0,33.676,10.4l-.116-.012A13.848,13.848,0,0,0,20.1,0H18.048A13.943,13.943,0,0,0,4.612,10.452,5.915,5.915,0,0,0,2,11.658a5.607,5.607,0,0,0,.093,8.66v9a4.654,4.654,0,0,0,2.092,3.853v1.273a3.6,3.6,0,0,0,7.209,0v-.513h15.4v.512a3.6,3.6,0,0,0,7.209,0V33.173a4.657,4.657,0,0,0,2.092-3.857V20.308A5.8,5.8,0,0,0,37.969,14.556ZM4.391,18.151a2.566,2.566,0,0,1-1.3-2.189,2.741,2.741,0,0,1,.912-2,2.534,2.534,0,0,1,1.713-.619,1.21,1.21,0,0,1,.349.059,2.732,2.732,0,0,1,2.167,2.75v9H29.884v-9.28a2.4,2.4,0,0,1,.969-1.968,2.433,2.433,0,0,1,2.13-.5,2.572,2.572,0,0,1,1.925,1.876,2.5,2.5,0,0,1-1.18,2.869l-.005,0a1.575,1.575,0,0,0-.79,1.378v9.863a1.484,1.484,0,0,1-.415,1.06,1.524,1.524,0,0,1-1.11.455H6.7a1.487,1.487,0,0,1-1.515-1.525V19.536A1.581,1.581,0,0,0,4.391,18.151Zm24.471-6.564a5.627,5.627,0,0,0-2.07,4.434v6.114H11.325v-5.91a5.972,5.972,0,0,0-3.6-5.5A10.933,10.933,0,0,1,18.046,3.092h2.048a10.673,10.673,0,0,1,10.235,7.617A5.359,5.359,0,0,0,28.862,11.587ZM8.233,34v.512a.513.513,0,1,1-1.025,0V34Zm22.675,0v.512a.513.513,0,1,1-1.025,0V34Z' fill={fill} />
  </svg>

Sofa.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string
}

export default Sofa

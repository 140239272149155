import { useQuery } from '@tanstack/react-query'
import { format } from 'date-fns'

import { axios, endpoints } from '~/settings'
import { WEEKDAY_NAMES_WITH_START_ON_MONDAY } from '~/utils/dateConstants'

const formatAvailabilities = (availabilities) => {
  return availabilities.map((availability, index) => ({
    id: index,
    label: format(availability.startDate, 'HH:mm'),
    startDate: availability.startDate,
    endDate: availability.endDate,
  }))
}

const getDayOptions = (availabilities) => {
  return Object.entries(availabilities || {})
    .filter(([, av]) => av.length > 0)
    .map(([weekDay]) => ({
      id: Number(weekDay),
      label: WEEKDAY_NAMES_WITH_START_ON_MONDAY[Number(weekDay)],
      availabilities: formatAvailabilities(availabilities[Number(weekDay)]),
    }))
}

const getAvailabilities = ({
  patientUsername,
  professionalUsername,
}) => {
  const endpoint = endpoints.availabilities.AVAILABILITIES(professionalUsername, patientUsername)
  
  return new Promise((resolve, reject) => {
    axios.get(endpoint)
      .then((response) => {
        const availabilities = {}
        
        Object.entries(response.data?.data?.availabilities).forEach(([weekDay, responseAvailabilities]) => {
          const formattedAvailability = responseAvailabilities.map((av) => {
            return {
              startDate: new Date(av.start_date),
              endDate: new Date(av.end_date),
            }
          })
          
          availabilities[weekDay] = formattedAvailability
        })
        
        resolve({ availabilities })
        
      })
      .catch((error) => {
        reject(error?.response?.status)
      })
  })
}

export const useGetReservationAvailabilities = ({
  patientUsername, 
  professionalUsername 
}) => {
  const { data, isLoading, isSuccess } = useQuery({
    queryKey: ['availabilities', patientUsername, professionalUsername],
    queryFn: () => getAvailabilities({ patientUsername, professionalUsername }),
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    retry: false,
  })
  
  const dayOptions = isSuccess ? getDayOptions(data?.availabilities) : []
 
  return {
    dayOptions,
    isLoading,
  }
}
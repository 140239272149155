export const WEEKDAY_NAMES_WITH_START_ON_MONDAY = {
  0: 'Segunda-feira',
  1: 'Terça-feira',
  2: 'Quarta-feira',
  3: 'Quinta-feira',
  4: 'Sexta-feira',
  5: 'Sábado',
  6: 'Domingo',
}

export const PLURAL_WEEKDAY_NAMES_WITH_START_ON_SUNDAY = {
  0: 'Domingos',
  1: 'Segundas-feiras',
  2: 'Terças-feiras',
  3: 'Quartas-feiras',
  4: 'Quintas-feiras',
  5: 'Sextas-feiras',
  6: 'Sábados'
}
import styled from 'styled-components'

import { colors } from '~/utils/stylesConstants'

export const Container = styled.div`
  width: 330px;
  background: ${colors.BLUE_01};
  border-radius: 5px;
  padding: 20px 30px 25px;
  margin-top: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  > p:first-child {
    width: 220px;
  }

  > button {
    margin-top: 10px;
    height: 40px;
    padding: 0;
  }
`

export const SpinnerWrapper = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    width: 30px;
    height: 30px;
    border-width: 3px;
  }
`
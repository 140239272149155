import { detect } from 'detect-browser'
import { isSupported } from 'twilio-video'

export const browserDetectionHandler = () => {
  const browser = detect()
  if (!browser) return null

  const iosAgents = ['safari', 'crios','ios']
  const mobileOs = ['ios', 'android']
  const systemOs = browser.os?.split(' ')[0].toLowerCase()

  let device = {
    browser: { ...browser },
    browsersSupported: [],
    isMobile: mobileOs.includes(systemOs),
    isSupportedBrowser: false,
    isSupportedVersion: true
  }

  if (iosAgents.includes(browser.name)) {
    device.browser.name = 'safari'
    device.isSupportedVersion = browser.version >= '12.1'
  }

  switch (systemOs) {
  case 'android':
    device.browsersSupported = ['chrome', 'firefox']
    break
  case 'linux':
    device.browsersSupported = ['chrome', 'firefox']
    break
  case 'ios':
    device.browsersSupported = device.browser.version < '14.3' ? ['safari'] : ['safari', 'chrome']
    break
  case 'macos':
  case 'mac':
    device.browsersSupported = ['chrome', 'firefox', 'safari', 'edge-chromium']
    break
  case 'windows':
    device.browsersSupported = ['chrome', 'firefox', 'edge-chromium']
    break
  default:
    device.browsersSupported = []
  }

  device.isSupportedBrowser = isSupported && !!device.browsersSupported.find(item => item === device.browser.name)
  return device
}

import React, { useContext, useEffect, useRef } from 'react'

import { interval } from 'd3-timer'

import { DevicesContext } from '~/contexts'

let clipId = 0
const getUniqueClipId = () => clipId++

const AudioContext = window.AudioContext || window.webkitAudioContext
let audioContext

export function initializeAnalyser(audioTrack) {
  audioContext = audioContext || new AudioContext()
  const audioSource = audioContext.createMediaStreamSource(new MediaStream([audioTrack]))

  const analyser = audioContext.createAnalyser()
  analyser.smoothingTimeConstant = 0.4
  analyser.fftSize = 512

  audioSource.connect(analyser)
  return analyser
}

function AudioLevel({ isEnabled }) {
  const ref = useRef(null)

  const { userAudioTrack: audioTrack } = useContext(DevicesContext)

  useEffect(() => {
    const SVGClipElement = ref.current

    if (audioTrack && isEnabled && SVGClipElement) {
      let analyser = initializeAnalyser(audioTrack)

      const reinitializeAnalyser = () => {
        analyser = initializeAnalyser(audioTrack)
      }

      /**
       * Aqui o AnalyserNode é reinicializado para o evento de 'focus' para evitar
       * problema conhecido no Safari onde o analysers para de funcionar quando o
       * usuário altera entre no navegador.
       */
      window.addEventListener('focus', reinitializeAnalyser)

      const sampleArray = new Uint8Array(analyser.frequencyBinCount)

      const timer = interval(() => {
        analyser.getByteFrequencyData(sampleArray)
        let values = 0

        const length = sampleArray.length
        for (let i = 0; i < length; i++) {
          values += sampleArray[i]
        }
        const volume = Math.min(21, Math.max(0, Math.log10(values / length / 3) * 14))

        SVGClipElement.setAttribute('y', String(21 - volume))
      }, 50)

      return () => {
        SVGClipElement.setAttribute('y', '30')
        timer.stop()
        window.removeEventListener('focus', reinitializeAnalyser)
      }
    }
  }, [audioTrack, isEnabled])

  const clipPathId = `audio-level-clip-${getUniqueClipId()}`

  return (
    <svg focusable="false" aria-hidden="true" width="8" height="31" viewBox="0 0 8 31">
      <defs>
        <clipPath id={clipPathId}>
          <rect ref={ref} x="0" y="30" width="6" height="30" rx="3" />
        </clipPath>
      </defs>

      <g transform="translate(-165 -198)">
        <rect width="8" height="31" rx="4" transform="translate(165 198)" fill={isEnabled ? '#fff' : '#c1c1c1'} />
        <path d="M0,0H6A0,0,0,0,1,6,0V20a3,3,0,0,1-3,3H3a3,3,0,0,1-3-3V0A0,0,0,0,1,0,0Z" transform="translate(166 206)" fill={isEnabled ? '#4ac326' : '#c1c1c1'} clipPath={isEnabled ? `url(#${clipPathId})` : null} />
        <g transform="translate(165 198)" fill="none" stroke="#fff" strokeWidth="2px">
          <rect width="8" height="31" rx="4" stroke="none" />
          <rect x="1" y="1" width="6" height="29" rx="3" fill="none" />
        </g>
      </g>
    </svg>
  )
}

export default React.memo(AudioLevel)

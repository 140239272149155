import React from 'react'

import { Button, Paper, Typography } from '@telavita-core/react-design-kit'

import * as S from './styles'

function formatSingularAndPluralMessage(totalSchedules) {
  return totalSchedules > 1
    ? <>{totalSchedules} consultas <br /> agendadas.</>
    : <>{totalSchedules} consulta <br /> agendada.</>
}

export const InitialContent = ({ 
  patientFirstName,
  patientTotalSchedules = 0,
  handleContinueReschedule,
}) => {
  return (
    <S.Container>
      {patientTotalSchedules > 0 
        ? (
          <Paper variant='warning' customClassName='infoSchedulePaper'>
            <Typography variant='content2' weight='bold'> INFORME AO PACIENTE: </Typography>
            <Typography variant='content2'>
              {patientFirstName}, você possui <strong>{formatSingularAndPluralMessage(patientTotalSchedules)}</strong>
            </Typography>
        
            <Typography variant='content2'>
              Ao remarcar toda a reserva, as consultas existentes também 
              serão <strong>remarcadas para os novos dias e horários definidos.</strong>
            </Typography>
        
            <Typography variant='content2'>
              Deseja continuar?
            </Typography>
          </Paper>
        )
        : (
          <Typography variant='content2' center>
            Ao concluir a mudança, o dia e o horário <br /> 
            anteriores são liberados para outros <br /> 
            pacientes agendarem.
          </Typography>
        )
      }

      <Button
        variant='contained'
        onClick={handleContinueReschedule}
      >
        Continuar
      </Button>
    </S.Container>
  )
}
import styled from 'styled-components'

export const PatientAlreadyHasScheduleWrapper = styled.div`

  > div {
    gap: 20px;
  }

  > button {
    margin-top: 30px;
    width: 100%;
  }
`

export const PatientAlreadyHasReservationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div {
    gap: 20px;
    margin-top: 20px;
  }

  & > p:first-child {
    margin-top: 20px;
  }
`

export const PlanNotAllowedWrapper = styled.div`
  text-align: center;
  max-width: 330px;
  margin-top: 15px;
`

export const OnlyParticularReservationWrapper = styled.div`
  margin-top: 30px;

  & > button {
    width: 100%;
    margin-top: 30px;
  }

  & > div {
    gap: 20px;
  }
`
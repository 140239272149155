import React from 'react'

import Button from '~/components/Button'
import CustomIcons, { iconList } from '~/components/CustomIcons'

import { colors } from '~/utils/stylesConstants'

export const ConectionErrorMessage = () => {
  return (
    <div className="FeedbackMessageBox">
      <div className="FeedbackMessageBox__icon">
        <CustomIcons icon={iconList.Alert} fill={colors.RED_01} />
      </div>

      <div className="FeedbackMessageBox__title">
        <p>Ops, houve algum problema!</p>
      </div>

      <div className='FeedbackMessageBox__message'>
        <p>{'Verifique sua conexão e tente entrar\n novamente na sua consulta.'}</p>
      </div>

      <div className='FeedbackMessageBox__action'>
        <Button id='exit-meet' classModifier='reverse'>sair</Button>
        <Button id='start-call'>entrar novamente</Button>
      </div>
    </div>
  )
}
import React from 'react'

import PropTypes from 'prop-types'

const Speaker = ({ fill = '#000', height = '24', width = '24' }) =>
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={fill}
      d="M12.914 4.5l-4.5 4.5h-4.5v6h4.5l4.5 4.5V4.5zm2.412 1.297l-0.218 0.815c2.441 0.654 4.134 2.861 4.134 5.388 0 2.527 -1.694 4.734 -4.134 5.388l0.218 0.815C18.132 17.451 20.086 14.904 20.086 12c0 -2.905 -1.954 -5.451 -4.76 -6.203zm-0.582 2.173l-0.218 0.815C15.982 9.176 16.992 10.492 16.992 12s-1.01 2.824 -2.467 3.215l0.218 0.815C16.566 15.542 17.836 13.886 17.836 12c0 -1.886 -1.27 -3.542 -3.092 -4.03zm-0.582 2.173l-0.218 0.815C14.416 11.085 14.742 11.51 14.742 12s-0.326 0.915 -0.799 1.041l0.218 0.815c0.838 -0.225 1.425 -0.989 1.425 -1.856 0 -0.868 -0.586 -1.632 -1.425 -1.856z"
    />
  </svg>

Speaker.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
}

export default Speaker
import styled from 'styled-components'

import { colors } from '~/utils/stylesConstants'

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  position: relative;

  .AvailabilitySelect {
    width: 158px;
    background-color: ${colors.WHITE};
    border-radius: 5px;
 
    > div {
      margin-top: 0;
      text-align: left;
    }
  }
`
import React, { useState } from 'react'

import { getDay, format } from 'date-fns'

import { Drawer, Typography } from '@telavita-core/react-design-kit'

import { InitialContent, RescheduleContent, SuccessContent } from './components'
import * as S from './styles'

import { PLURAL_WEEKDAY_NAMES_WITH_START_ON_SUNDAY } from '~/utils/dateConstants'

const ModalStatuses = {
  INITIAL: 'INITIAL',
  RESCHEDULE: 'RESCHEDULE',
  SUCCESS: 'SUCCESS'
}

export const RescheduleReservation = ({
  reservation,
  handleClose,
  onRefresh,
  open,
}) => {
  const [modalStatus, setModalStatus] = useState(ModalStatuses.INITIAL)
  const [rescheduleSuccessData, setRescheduleSuccessData] = useState(null)
  
  const reservationWeekdayName = PLURAL_WEEKDAY_NAMES_WITH_START_ON_SUNDAY[getDay(new Date(reservation?.start_date))]
  const reservationHour = format(new Date(reservation?.start_date), 'HH')
  const recurrentReservationDate = `${reservationWeekdayName}, ${reservationHour}h`

  const [patientFistName] = reservation?.patient_full_name.split(' ')
  
  function handleContinueReschedule() {
    setModalStatus(ModalStatuses.RESCHEDULE)
  }

  function handleCloseModal() {
    if (modalStatus === ModalStatuses.SUCCESS) {
      onRefresh()
    }
    
    setModalStatus(ModalStatuses.INITIAL)
    handleClose()
  }

  function handleSuccess(rescheduleSuccessData) {
    setModalStatus(ModalStatuses.SUCCESS)
    setRescheduleSuccessData(rescheduleSuccessData)
  }

  return (
    <Drawer
      open={open}
      setOpen={() => {
        handleClose()
        setModalStatus(ModalStatuses.INITIAL)
      }}
      anchor='right'
      customClassName='Reservation__drawer'
    >
      <S.Container>
        {modalStatus !== ModalStatuses.SUCCESS  && (
          <S.Header>
            <Typography center variant='header4'>
              Mudar dia da reserva
            </Typography>
            <Typography variant='content2' center inheritColor>
              Paciente <b>{reservation.patient_full_name}</b>
              <br />
              <Typography variant='content2' color='danger' center>
                Reserva atual <b>{recurrentReservationDate}</b>
              </Typography>
            </Typography>
          </S.Header>
        )}
      
        {modalStatus === ModalStatuses.INITIAL && (
          <InitialContent
            patientFirstName={patientFistName}
            handleContinueReschedule={handleContinueReschedule} 
          />
        )}

        {modalStatus === ModalStatuses.RESCHEDULE && (
          <RescheduleContent 
            reservation={reservation} 
            handleSuccess={handleSuccess} 
          />
        )}

        {modalStatus === ModalStatuses.SUCCESS && (
          <SuccessContent 
            handleClose={handleCloseModal} 
            patientFirstName={patientFistName}
            {...rescheduleSuccessData}
          />
        )}
      </S.Container>
    </Drawer>
  )
}
import { useQuery } from '@tanstack/react-query'
import { format } from 'date-fns'

import { formatEvents, getDaysWithEvents, getMinAndMaxDatesFromDateList } from '../utils'

import { useSchedule } from '~/hooks'
import { axios, endpoints } from '~/settings'

const PROFESSIONAL_AVAILABILITIES_QUERY_KEY = 'professional-availabilities'

const queryConfig = {
  staleTime: 0,
  cacheTime: 0,
  refetchOnWindowFocus: false,
  retry: false,
  enabled: false,
}

async function getProfessionalAvailabilities({
  restrictions,
  professionalUsername,
  patientUsername,
}) {
  const formattedStartAndEndDate = getMinAndMaxDatesFromDateList(restrictions)
  const formattedRestrictionDate = restrictions.map((item) => format(new Date(item), 'yyyy-MM-dd'))
    
  const { data } = await axios.get(endpoints.availabilities.PROFESSIONAL_AVAILABILITIES(professionalUsername), {
    params: {
      schedule_type: 'PSIC',
      patient_username: patientUsername,
      ...formattedStartAndEndDate
    }
  })
    
  const availabilities = data?.data?.availabilities
  const daysWithEvents = getDaysWithEvents(availabilities, formattedRestrictionDate)
    
  return formatEvents(daysWithEvents)
}

export const useGetProfessionalAvailabilities = ({ restrictions }) => {
  const { patientData, professionalData } = useSchedule()
  
  const { data, isFetching, refetch, isError } = useQuery({
    queryKey: [PROFESSIONAL_AVAILABILITIES_QUERY_KEY, restrictions],
    queryFn: () => getProfessionalAvailabilities({
      restrictions,
      patientUsername: patientData.username,
      professionalUsername: professionalData.username,
    }),
    ...queryConfig,
  })

  return { data, isFetching, refetch, isError }
}
import React from 'react'

import { endOfWeek, format, startOfWeek } from 'date-fns'
import { ptBR } from 'date-fns/locale'

import { Typography } from '@telavita-core/react-design-kit'

import * as S from './styles'

const formatDateHeader = (date) => format(date, 'dd/MM')
const formatMonthAndYear = (date) => format(date, 'LLL yyyy', { locale: ptBR })

export const WeeklyCalendar = ({ initialDate, handleDateChange }) => {
  const minDate = startOfWeek(initialDate, { weekStartsOn: 0 })
  const maxDate = endOfWeek(initialDate, { weekStartsOn: 0 })

  const formattedDateHeader = formatDateHeader(initialDate)
  const formattedMonthAndYear = formatMonthAndYear(initialDate)
  
  return (
    <S.Container>
      <S.CalendarHeader>
        <Typography variant="content2">
          Mudar <strong>{formattedDateHeader}</strong> para:
        </Typography>

        <Typography variant="content2" weight="bolder">
          {formattedMonthAndYear}
        </Typography>
      </S.CalendarHeader>

      <S.CalendarContainer
        locale='pt-BR'
        showNeighboringMonth
        next2Label={null}
        prev2Label={null}
        nextLabel={null}
        prevLabel={null}
        navigationLabel={null}
        minDate={minDate}
        maxDate={maxDate}
        calendarType="US"
        value={initialDate}
        onChange={handleDateChange}
      />
    </S.Container>
  )
}

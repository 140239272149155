import React from 'react'

import Button from '~/components/Button'
import CustomIcons, { iconList } from '~/components/CustomIcons'
import Spinner from '~/components/Spinner'

import { externalRoutes } from '~/settings'
import { colors } from '~/utils/stylesConstants'

export const ReadyToCallMessage = ({ action, loading }) => {
  const handleBtnClick = () => {
    sessionStorage.clear()
    window.location.assign(externalRoutes.DASHBOARD)
  }

  return (
    <div className='FeedbackMessageManager'>
      <div className="FeedbackMessageBox">
        <div className="FeedbackMessageBox__icon">
          <CustomIcons icon={iconList.Check} fill={colors.GREEN} />
        </div>

        <div className="FeedbackMessageBox__title">
          <p>Tudo pronto!</p>
        </div>

        <div className='FeedbackMessageBox__message'>
          <p>{'Use o botão abaixo para iniciar\n sua consulta.'}</p>
        </div>

        <div className='FeedbackMessageBox__action'>
          <Button id='exit-meet' classModifier='reverse' type='link' onButtonClick={handleBtnClick}>
            sair
          </Button>

          <Button id='start-call' onButtonClick={() => action()}>
            {
              loading ? <Spinner color='white' /> : sessionStorage.getItem('connected') ? 'entrar novamente' : 'iniciar consulta'
            }
          </Button>
        </div>
      </div>
    </div>
  )
}
import styled from 'styled-components'

import { colors } from '~/utils/stylesConstants'

export const Container = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  margin-top: 3rem;

  & > div {
    max-width: 100%;
  }
`

export const TextWrapper = styled.div`
  margin: 10px 0 20px;
  > p {
    > span {
      font-weight: 600;
      color: ${colors.RED_02};
    }
  }
`

export const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20rem;
`

export const ButtonWrapper = styled.div`
  width: 100%;

  & > p {
    color: ${colors.GREY_06};
    max-width: 200px;
    margin: 0 auto;
  }

  & > button {
    width: 100%;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    & .customSpinner {
      width: 30px;
      height: 30px;
      border: 4px solid;
      border-top-color: transparent;
    }
  }

`
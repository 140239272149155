export const primaryColor = '#391DDD'
export const secondaryColor = '#FF909E'
export const darkerPrimaryColor = '#2f17b5'
export const darkerSecondaryColor = '#FF7C8C'

export const colors = {
  PRIMARY: '#1f35b5',
  PRIMARY_LIGHT: '#dfe3f8',
  PRIMARY_DARK: '#172888',
  SECONDARY: '#22cbd3',
  SECONDARY_LIGHT: '#d3f5f6',
  SECONDARY_DARK: '#1a989e',
  BLACK: '#000',
  WHITE: '#fff',
  GREEN: '#4ac326',
  RED_01: '#ff4949',
  RED_02: '#FF3D3D',
  ORANGE: '#f9a435',
  YELLOW: '#ffffb5',
  GREY_01: '#f5f5f5',
  GREY_02: '#ebebeb',
  GREY_03: '#e2e2e2',
  GREY_04: '#c1c1c1',
  GREY_05: '#acacac',
  GREY_06: '#898989',
  GREY_07: '#4b4b4b',
  BLUE_01: '#E8EAF8',
  BLUE_02: '#BBC2E9',
  BLUE_03: '#3C4FBE',
}

import React from 'react'

import { IconButton } from '@telavita-core/react-design-kit'

import CallEndButton from '~/components/CallEndButton'
import OptionsButton from '~/components/OptionsButton/OptionsButton'

import { browserDetectionHandler } from '~/handlers'

const { isMobile } = browserDetectionHandler()

const CallControllers = ({
  showElement,
  toggleModal,
  toggleChatBox,
  isProfessional,
  onReservationClick,
  onRescheduleReservationClick,
  onSettingsClick,
  isAudioEnabled,
  isVideoEnabled,
  onToggleAudio,
  onToggleVideo,
  onPatientDetailsClick,
  showReservationOption,
  showRescheduleReservationOption
}) => {
  return (
    <div className={`CallControllers${showElement ? '' : '--hide'}`}>
      <div className='CallControllers__buttons'>
        <IconButton
          icon={isAudioEnabled ? 'MicrophoneOn' : 'MicrophoneOff'}
          variant={'outlined'}
          color={!isAudioEnabled ? 'danger' : null}
          tooltip={isAudioEnabled ? 'Desligar microfone' : 'Ligar microfone'}
          onClick={onToggleAudio}
        />
        <IconButton
          icon={isVideoEnabled ? 'CameraOn' : 'CameraOff'}
          variant={'outlined'}
          color={!isVideoEnabled ? 'danger' : null}
          tooltip={isVideoEnabled ? 'Desligar vídeo' : 'Ligar vídeo'}
          onClick={onToggleVideo}
        />
        <OptionsButton
          onChatClick={toggleChatBox}
          onRescheduleReservationClick={onRescheduleReservationClick}
          onReservationClick={onReservationClick}
          onSettingsClick={onSettingsClick}
          onPatientDetailsClick={onPatientDetailsClick}
          isProfessional={isProfessional}
          isOpen={!showElement}
          showRescheduleReservationOption={showRescheduleReservationOption}
          showReservationOption={showReservationOption} 
        />
        <CallEndButton hasAlt={!isMobile} handleLeaveCall={toggleModal} />
      </div>
    </div>
  )
}

export default CallControllers

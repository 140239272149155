import React from 'react'

import Button from '~/components/Button'

import { externalRoutes } from '~/settings'

export const UnexpectedError = () => {
  return (
    <div className='ConnectionError'>
      <div className='ConnectionError__title'>
        <p>Ops, houve algum problema</p>
      </div>

      <div className='ConnectionError__message'>
        <p>Verifique sua conexão e tente entrar novamente na sua consulta.</p>
      </div>

      <div className='ConnectionError__button'>
        <Button id="exit" onButtonClick={() => window.location.assign(externalRoutes.DASHBOARD)}>
          entrar novamente</Button>
      </div>
    </div>
  )
}
const apiEndpoints = {
  authentication: '/v0/auth',
  availabilities: '/v0/availabilities',
  reservations: '/v0/reservations',
  restrictions: '/v0/restrictions',
  virtualRooms: '/v0/virtual-rooms',
  schedules: '/v0/schedules',
  medicalRecord: '/v0/medical-record',
}

const serializer = params => '?' + Object.keys(params).map(key => key + '=' + params[key]).join('&')

export const endpoints = {
  authentication: {
    FACE_ID: `${apiEndpoints.authentication}/faceid/`
  },
  virtualRooms: {
    AUTH: roomHash => `${apiEndpoints.virtualRooms}/${roomHash}/`,
    JOIN: roomHash => `${apiEndpoints.virtualRooms}/${roomHash}/access-tokens/`,
    LOG: roomHash => `${apiEndpoints.virtualRooms}/${roomHash}/logs/`
  }, 
  reservations: {
    RESERVATION: `${apiEndpoints.reservations}/`
  },
  availabilities: {
    AVAILABILITIES: (professional, patient) => `${apiEndpoints.availabilities}/${professional}/${patient}/`,
    PROFESSIONAL_AVAILABILITIES: professionalUsername => `${apiEndpoints.availabilities}/professional/${professionalUsername}/`,
  },
  restrictions: {
    RESTRICTIONS: (role, username, params) => `${apiEndpoints.restrictions}/${role}/${username}/${params ? serializer(params) : ''}`
  },
  schedules: {
    SCHEDULES: (params) => `${apiEndpoints.schedules}/${params ? serializer(params) : ''}`,
    TOKEN_VALIDATION: (scheduleId) => `${apiEndpoints.schedules}/${scheduleId}/token-validation/`,
  },
  medicalRecord: {
    SEARCH_MEDICAL_RECORD: (patientUUID) => `${apiEndpoints.medicalRecord}/${patientUUID}/`,
    CREATE_MEDICAL_RECORD: `${apiEndpoints.medicalRecord}/`,
  },
}
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import CallRoom from '~/views/CallRoom'
import FaceId from '~/views/FaceId'
import { views } from '~/views/viewsCode'
import WaitingRoom from '~/views/WaitingRoom'

import SettingsModal from '~/components/SettingsModal'

import { DevicesProvider } from '../../contexts/DevicesContext'

import { useSchedule } from '~/hooks'

const MeetingView = () => {
  const { hash } = useParams()

  const [view, setView] = useState()
  const [accessToken, setAccessToken] = useState(null)
  const [meeting, setMeeting] = useState(null)
  const [showSettingsModal, setShowSettingsModal] = useState(false)
  const { setSchedule } = useSchedule()
  
  const updateMeeting = (data) => {
    setMeeting(prevMeeting => ({ ...prevMeeting, ...data }))
    setSchedule(prevSchedule => ({ ...prevSchedule, ...data }))
  }
  
  function handleShowSettingsModal() {
    setShowSettingsModal(true)
  }

  function handleCloseSettingsModal() {
    setShowSettingsModal(false)
  }

  if (view === views.callRoom) {
    return (
      <DevicesProvider>
        <CallRoom 
          updateView={setView}
          accessToken={accessToken}
          meeting={meeting}
          onOpenSettingsModal={handleShowSettingsModal}
        />
        {showSettingsModal &&
          <SettingsModal
            onClose={handleCloseSettingsModal}
          />
        }
      </DevicesProvider>
    )
  }

  if (view === views.faceId) {
    return <FaceId />
  }

  return (
    <DevicesProvider>
      <WaitingRoom
        hash={hash}
        updateView={setView}
        accessToken={accessToken}
        updateAccessToken={setAccessToken}
        meeting={meeting}
        updateMeeting={updateMeeting}
        onOpenSettingsModal={handleShowSettingsModal}
      />
      {showSettingsModal &&
        <SettingsModal
          onClose={handleCloseSettingsModal}
        />
      }
    </DevicesProvider>
  )
}

export default MeetingView

import React, { useState, useRef, useEffect, useContext } from 'react'

import { Typography, Select, useDimensions } from '@telavita-core/react-design-kit'

import CustomIcons, { iconList } from '~/components/CustomIcons'

import AudioLevel from '../AudioLevel'
import BaseModal from '../BaseModal'
import Tabs from '../Tabs'

import { DevicesContext } from '~/contexts'
import testSpeakerSound from '~/static/sounds/test-speaker.ogg'
import { colors } from '~/utils/stylesConstants'

const optionsTabs = [
  { title: 'Áudio', id: 1 },
  { title: 'Vídeo', id: 2 },
]

const SettingsModal = ({ 
  onClose,
}) => {
  const {
    videoDevices,
    speakerDevices,
    microphoneDevices,
    activeSpeakerDevice,
    setActiveSpeakerDevice,
    activeMicrophoneDevice,
    setActiveMicrophoneDevice,
    activeVideoDevice,
    setActiveVideoDevice,
    userVideoTrack,
    audioEnabled,
    videoEnabled,
  } = useContext(DevicesContext)

  const { isMobile } = useDimensions()

  const videoRef = useRef(null)
  const audioRef = useRef(null)

  const [selectedTab, setSelectedTab] = useState(1)
  const [isPlaying, setIsPlaying] = useState(false)

  async function playTestAudio() {
    setIsPlaying(true)
    audioRef.current.onended = () => setIsPlaying(false)
    audioRef.current.play()
  }

  function handleChangeTab(id) {
    setSelectedTab(id)
  }

  function handleSelectDeviceOption(deviceId, deviceGroup) {
    const device = deviceGroup.find(device => device.deviceId === deviceId)

    switch (device.kind) {
    case 'audiooutput':
      setActiveSpeakerDevice(device)
      break
    case 'audioinput':
      setActiveMicrophoneDevice(device)
      break
    case 'videoinput':
      setActiveVideoDevice(device)
      break
    }
  }

  function getDeviceOptions(devices) {
    return devices.map(device => {
      return {
        id: device?.deviceId,
        label: device?.label,
      }
    })
  }

  // `setSinkId` não é suportado no Safari
  // https://caniuse.com/?search=setSinkId
  useEffect(() => {
    if (activeSpeakerDevice && selectedTab === 1) {
      if (audioRef.current.setSinkId) {
        audioRef.current.setSinkId(activeSpeakerDevice.deviceId)
      }
    }
  }, [activeSpeakerDevice, selectedTab])

  useEffect(() => {
    if (userVideoTrack && selectedTab === 2) {
      const ms = new MediaStream()
      ms.addTrack(userVideoTrack)
      videoRef.current.srcObject = ms
    }
  }, [userVideoTrack, selectedTab])

  return (
    <BaseModal>
      <div className='SettingsModal__container'>
        <h2 className='SettingsModal__container__title'>Configurações</h2>

        <button className='SettingsModal__container__closeButton' onClick={onClose}>
          <CustomIcons
            icon={iconList.X}
          />
        </button>

        <div className='SettingsModal__container__tabs'>
          <Tabs
            tabs={optionsTabs}
            onSelectTab={handleChangeTab}
            selectedItem={selectedTab}
          />
        </div>

        {selectedTab === 1 && (
          <div className='SettingsModal__container__tabs__audioContainer'>
            <audio
              ref={audioRef}
              src={testSpeakerSound}
              style={{ display: 'none' }}
            />

            <Typography
              italic
              center
              variant='content3'
              customClassName='SettingsModal__container__tabs__audioContainer__message'
            >
              Para evitar eco, use o microfone e o alto-falante do mesmo dispositivo
            </Typography>

            <Typography
              weight='bolder'
              variant='content2'
              color={!audioEnabled && 'danger'}
              customClassName='SettingsModal__container__tabs__audioContainer__selectLabel'
            >
              Microfone
            </Typography>
            <div className='SettingsModal__container__tabs__audioContainer__select SettingsModal__container__tabs__audioContainer__select--microphone'>
              <Select
                options={getDeviceOptions(microphoneDevices)}
                onSelect={(microphone) => handleSelectDeviceOption(microphone.id, microphoneDevices)}
                selected={{
                  id: activeMicrophoneDevice?.deviceId,
                  label: activeMicrophoneDevice?.label
                }}
              />
              <AudioLevel isEnabled={audioEnabled} />
            </div>
            {!audioEnabled && (
              <Typography
                variant='content3'
                color='danger'
                italic
                inlineStyles={{
                  width: '100%',
                  marginTop: 10,
                }}
              >
                Você desligou o microfone
              </Typography>
            )}

            {speakerDevices.length > 0 &&
            <>
              <Typography
                weight='bolder'
                variant='content2'
                customClassName='SettingsModal__container__tabs__audioContainer__selectLabel'
              >
                Alto-Falante
              </Typography>
              <div className='SettingsModal__container__tabs__audioContainer__select SettingsModal__container__tabs__audioContainer__select--speaker'>
                <Select
                  options={getDeviceOptions(speakerDevices)}
                  onSelect={(speaker) => handleSelectDeviceOption(speaker.id, speakerDevices)}
                  selected={{
                    id: activeSpeakerDevice?.deviceId,
                    label: activeSpeakerDevice?.label,
                  }}
                />
                <button
                  onClick={playTestAudio}
                  disabled={isPlaying}
                  className={`SettingsModal__container__tabs__audioContainer__select__button${isPlaying ? '--disabled' : ''}`}
                >
                  <CustomIcons
                    icon={iconList.Speaker}
                    fill={isPlaying ? colors.GREY_04 : colors.PRIMARY}
                  />
                  <Typography
                    variant='content2'
                    weight='bolder'
                    inlineStyles={{
                      color: isPlaying ? colors.GREY_04 : colors.PRIMARY
                    }}
                  >
                    Teste a saída do som
                  </Typography>
                </button>
              </div>
            </>
            }
          </div>
        )}

        {selectedTab === 2 && (
          <div className='SettingsModal__container__tabs__videoContainer'>
            {!videoEnabled &&
              <div
                className='SettingsModal__container__tabs__videoContainer--video-off'
              >
                <CustomIcons 
                  icon={iconList.CameraOff}
                  fill={colors.WHITE}
                />
              </div>
            }
              
            <video
              ref={videoRef}
              autoPlay
              playsInline
              muted
              style={{
                display: !videoEnabled && 'none'
              }}
            />

            {!isMobile && (
              <>
                <Typography
                  weight='bolder'
                  variant='content2'
                  color={!videoEnabled && 'danger'}
                  customClassName='SettingsModal__container__tabs__audioContainer__selectLabel'
                >
                  Câmera
                </Typography>

                <div className='SettingsModal__container__tabs__videoContainer__select'>
                  <Select
                    options={getDeviceOptions(videoDevices)}
                    onSelect={(video) => handleSelectDeviceOption(video.id, videoDevices)}
                    selected={{
                      id: activeVideoDevice?.deviceId,
                      label: activeVideoDevice?.label
                    }}
                  />
                </div>
              </>
            )}
            
            {!videoEnabled && (
              <Typography
                variant='content3'
                color='danger'
                italic
                inlineStyles={{
                  width: '100%',
                  marginTop: 10,
                }}
              >
                Você desligou a câmera
              </Typography>
            )}

          </div>
        )}
      </div>
    </BaseModal>
  )
}

export default SettingsModal

import styled from 'styled-components'

import { colors } from '~/utils/stylesConstants'

export const Container = styled.div`
  padding: 0 14px;

  > p:first-child {
    margin-top: 20px;
  }

  .patientPhoneText {
    margin: 20px 0 30px
  }

  .infoSchedulePaper {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .warningPaper {
    max-width: 100%;
  }

  > button {
    width: 100%;
    margin-top: 40px;
  }
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 20px;
`

export const DangerText = styled.span`
  color: ${colors.RED_01};
`
import React, { createContext, useCallback, useEffect, useState } from 'react'

import throttle from 'lodash.throttle'

import { twilioMediaErrorHandler } from '~/handlers'

const constraints = {
  video: {
    facingMode: 'user'
  },
  audio: {
    echoCancellation: true,
    noiseSuppression: true,
  }
}

export const DevicesContext = createContext()

export const DevicesProvider = ({ children }) => {
  const [devices, setDevices] = useState([])

  const [activeSpeakerDevice, setActiveSpeakerDevice] = useState(null)
  const [activeMicrophoneDevice, setActiveMicrophoneDevice] = useState(null)
  const [activeVideoDevice, setActiveVideoDevice] = useState(null)

  const [userAudioTrack, setUserAudioTrack] = useState(null)
  const [userVideoTrack, setUserVideoTrack] = useState(null)

  const [mediaErrorMessage, setMediaErrorMessage] = useState(null)
  
  const [audioEnabled, setAudioEnabled] = useState(true)
  const [videoEnabled, setVideoEnabled] = useState(true)

  const speakerDevices = devices.filter(device => device.kind === 'audiooutput')
  const videoDevices = devices.filter(device => device.kind === 'videoinput')
  const microphoneDevices = devices.filter(device => device.kind === 'audioinput')  

  function handleToggleVideo() {
    const isEnabled = !videoEnabled

    if (isEnabled) {
      acquireVideoTrack()
      setVideoEnabled(true)
    } else {
      userVideoTrack?.stop()
      setUserVideoTrack(null)
      setVideoEnabled(false)
    }
  }

  function handleToggleAudio() {
    const isEnabled = !audioEnabled

    if (isEnabled) {
      acquireAudioTrack()
      setAudioEnabled(true)
    } else {
      userAudioTrack?.stop()
      setUserAudioTrack(null)
      setAudioEnabled(false)
    }
  }

  // Adquire a track do dispositivo de microfone
  const acquireAudioTrack = useCallback(() => {
    if (!activeMicrophoneDevice) return

    async function getNewMedia() {
      try {
        const newAudioConstraints = { audio: { deviceId: { exact: activeMicrophoneDevice.deviceId } } }
        const media = await navigator.mediaDevices.getUserMedia(newAudioConstraints)
        setUserAudioTrack(media.getTracks()[0])
      } catch (err) {
        console.log(err)
      }
    }

    getNewMedia()
  }, [activeMicrophoneDevice])

  // Adquire a track do dispositivo de vídeo
  const acquireVideoTrack = useCallback(() => {
    if (!activeVideoDevice) return

    async function getNewMedia() {
      try {
        const newVideoConstraints = { video: { deviceId: { exact: activeVideoDevice.deviceId } } } 
        const media = await navigator.mediaDevices.getUserMedia(newVideoConstraints)
        setUserVideoTrack(media.getTracks()[0])
      } catch (err) {
        console.log(err)
      }
    }

    getNewMedia()
  }, [activeVideoDevice])

  // Requisita a permissão dos dispositivos e enumera a lista inicial de dispositivos
  const getMediaDevices = useCallback(async () => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia(constraints)
      const devices = await navigator.mediaDevices.enumerateDevices()
  
      const speakerDevices = devices.filter(device => device.kind === 'audiooutput')
      const newSpeakerDevice = speakerDevices.find(speaker => speaker.deviceId === 'default') || speakerDevices.length > 0 ? speakerDevices[0] : null
      const newMicrophoneDevice = devices.find(device => device.deviceId === mediaStream.getAudioTracks()[0].getSettings().deviceId)
      const newVideoDevice = devices.find(device => device.deviceId === mediaStream.getVideoTracks()[0].getSettings().deviceId)
  
      if (!activeSpeakerDevice || newSpeakerDevice.label !== activeSpeakerDevice.label) {
        setActiveSpeakerDevice(newSpeakerDevice)
      }
  
      if (!activeMicrophoneDevice || newMicrophoneDevice.label !== activeMicrophoneDevice.label) {
        setActiveMicrophoneDevice(newMicrophoneDevice)
      }
  
      if (!activeVideoDevice || newVideoDevice.label !== activeVideoDevice.label) {
        setActiveVideoDevice(newVideoDevice)
      }
  
      setDevices(devices)
      mediaStream.getTracks().forEach(track => track.stop())

    } catch (err) {
      if (err && err.name && err.message) {
        console.error(err)
        const errorMessage = twilioMediaErrorHandler(err.name, err.message)
        setMediaErrorMessage(errorMessage)
      } else {
        throw err
      }
    }
  }, [])

  useEffect(() => {
    if (userAudioTrack) { 
      setAudioEnabled(state => {
        return state !== null ? state : true
      })
    }
  }, [userAudioTrack])

  useEffect(() => {
    if (userVideoTrack) {
      setVideoEnabled(state => {
        return state !== null ? state : true
      })
    }
  }, [userVideoTrack])

  useEffect(() => {
    getMediaDevices()
  }, [])

  // Observa mudanças na lista de devices do navegador
  useEffect(() => {
    const throttleDeviceChange = throttle(getMediaDevices, 1000, {
      leading: true,
      trailing: false
    })

    navigator.mediaDevices.addEventListener('devicechange', throttleDeviceChange)

    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', throttleDeviceChange)
    }
  }, [])

  useEffect(() => {
    acquireAudioTrack()
  }, [activeMicrophoneDevice])

  useEffect(() => {
    acquireVideoTrack()
  }, [activeVideoDevice])

  return (
    <DevicesContext.Provider value={{
      devices,
      videoDevices,
      speakerDevices,
      microphoneDevices,

      activeSpeakerDevice,
      setActiveSpeakerDevice,
      activeMicrophoneDevice,
      setActiveMicrophoneDevice,
      activeVideoDevice,
      setActiveVideoDevice,

      userAudioTrack,
      acquireAudioTrack,

      userVideoTrack,
      acquireVideoTrack,

      mediaErrorMessage,

      audioEnabled,
      videoEnabled,

      handleToggleVideo,
      handleToggleAudio,
    }
    }>
      {children}
    </DevicesContext.Provider>
  )
}

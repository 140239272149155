import React from 'react'

import { IconButton } from '@telavita-core/react-design-kit'

const SettingsButton = ({ onClick, hasError }) => {
  return (
    <IconButton
      icon='Settings'
      variant='contained'
      tooltip={hasError ? 'Você deve permitir o acesso à camera e microfone' : 'Abrir configurações'}
      onClick={onClick}
      disabled={hasError}
    />
  )
}

export default SettingsButton

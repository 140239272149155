import { ConectionErrorMessage } from './messageComponents/ConectionErrorMessage'
import { HashNotFoundMessage } from './messageComponents/HashNotFoundMessage'
import { PreparingRoomMessage } from './messageComponents/PreparingRoomMessage'
import { ReadyToCallMessage } from './messageComponents/ReadyToCallMessage'
import { UnauthorizedErrorMessage } from './messageComponents/UnauthorizedErrorMessage'
import { UnknownErrorMessage } from './messageComponents/UnknownErrorMessage'

export const messageComponents = {
  ConectionErrorMessage,
  HashNotFoundMessage,
  PreparingRoomMessage,
  ReadyToCallMessage,
  UnauthorizedErrorMessage,
  UnknownErrorMessage
}

export const messageCode = {
  CALL_READY: 'ReadyToCallMessage',
  ERROR_AUTH: 'UnauthorizedErrorMessage',
  ERROR_CONNECTION: 'ConectionErrorMessage',
  ROOM_NOT_FOUND: 'HashNotFoundMessage',
  PREPARING_ROOM: 'PreparingRoomMessage',
  UNKNOWN_ERROR: 'UnknownErrorMessage'
}
const PATIENT_PROFILE_CODE = 'TVTVPACI'
const PSIC_PROFILE_CODE = 'TVTVPSIC'

export function useProfile({ user }) {
  const isProfessional = user?.profile_code === PSIC_PROFILE_CODE
  const isPatient = user?.profile_code === PATIENT_PROFILE_CODE
    
  return {
    isPatient,
    isProfessional
  }
}
import { useEffect, useState } from 'react'

import { formatISO } from 'date-fns'

import { axios, endpoints } from '~/settings'

const getProfessionalRestrictions = ({
  professionalUsername,
  patientUsername,
  startDate,
  endDate,
}) => {
  const endpoint = endpoints.restrictions.RESTRICTIONS('professional', professionalUsername)

  const params = {
    start_date: formatISO(startDate),
    end_date: formatISO(endDate),
    patient_username: patientUsername,
  }

  return new Promise((resolve, reject) => {
    axios.get(endpoint, { params })
      .then((response) => {
        const responseData = response.data
        const result = {}

        result.firstAvailableEndDate = new Date(responseData.data.first_available_end_date)
        result.firstAvailableStartDate = new Date(responseData.data.first_available_start_date)
        result.reservationEndDate = responseData.data.reservation_end_date
        result.reservationStartDate = responseData.data.reservation_start_date
        result.restrictions = responseData.data.restrictions.map(restriction => new Date(restriction))
        
        resolve(result)
      })
      .catch((error) => {
        reject(error?.response?.status)
      })
  })
}

export const useGetProfessionalRestrictions = ({
  professionalUsername,
  patientUsername,
  startDate,
  endDate,
  selectedWeekDay,
  selectedHour
}) => {
  const [restrictions, setRestrictions] = useState([])
  const [professionalRestrictions, setProfessionalRestrictions] = useState({})
  const [loadingProfessionalRestrictions, setLoadingProfessionalRestrictions] = useState(false)
  
  useEffect(() => {
    if (!selectedWeekDay || !selectedHour) return
    async function verifyProfessionalRestrictions() {
      setLoadingProfessionalRestrictions(true)
      
      const response = await getProfessionalRestrictions({
        professionalUsername,
        patientUsername,
        startDate,
        endDate
      })

      setRestrictions(response.restrictions)
      setProfessionalRestrictions(response)
      setLoadingProfessionalRestrictions(false)
    }

    void verifyProfessionalRestrictions()
  }, [selectedWeekDay, selectedHour, patientUsername, professionalUsername])

  return {
    restrictions,
    professionalRestrictions,
    loadingProfessionalRestrictions
  }
}
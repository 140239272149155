import React, { useEffect, useState } from 'react'

import Button from '~/components/Button'

import { browserDetectionHandler } from '~/handlers'

export const BrowserError = () => {
  const getBrowser = browserDetectionHandler()

  const [chrome, setChrome] = useState(null)
  const [firefox, setFirefox] = useState(null)
  const [edge, setEdge] = useState(null)
  const [safari, setSafari] = useState(null)

  const [needUpdate, setNeedUpdate] = useState(false)

  useEffect(() => {
    getBrowser.browsersSupported.map(item => {
      if (item === 'chrome') setChrome(true)
      if (item === 'firefox') setFirefox(true)
      if (item === 'edge-chromium') setEdge(true)
      if (item === 'safari') setSafari(true)
    })

    setNeedUpdate(!getBrowser.isSupportedVersion)
  }, [getBrowser])

  return (
    <div className='BrowserError'>
      <div className='BrowserError__title'>
        <p>
          {
            needUpdate ?
              `A consulta não funciona\nnesta versão do navegador\n(${getBrowser.browser.version})` :
              'A consulta não funciona neste navegador'
          }
        </p>
      </div>

      <div className='BrowserError__message'>
        <p>
          {
            needUpdate ?
              'Para conseguir entrar na consulta, atualize para a versão mais recente ou utilize outro navegador.' :
              'Para conseguir entrar na consulta, utilize um dos navegadore abaixo.'
          }
        </p>
      </div>

      <div className='BrowserError__buttons'>
        {
          chrome &&
          <Button classModifier='reverse' id='download-chrome'
            onButtonClick={() => window.location.assign('https://www.google.com/chrome')}>
            chrome
          </Button>
        }
        {
          firefox &&
          <Button classModifier='reverse' id='download-firefox'
            onButtonClick={() => window.location.assign('https://www.mozilla.org/firefox/new')}>
            firefox
          </Button>
        }
        {
          edge &&
          <Button classModifier='reverse' id='download-edge'
            onButtonClick={() => window.location.assign('https://www.microsoft.com/edge')}>
            edge
          </Button>
        }
        {
          safari &&
          <Button classModifier='reverse' id='download-safari'
            onButtonClick={() => window.location.assign('https://support.apple.com/downloads/safari')}>
            safari
          </Button>
        }
      </div>
    </div>
  )
}
import React from 'react'

import { Icon } from '@telavita-core/react-design-kit'

import { 
  OnlyParticularReservation, 
  PatientAlreadyHasReservation, 
  PatientAlreadyHasSchedule, 
  PlanNotAllowed 
} from './components'

function renderRestrictionFeedback({
  handleContinueWithReservation,
  message, 
  patientFirstName,
  totalSchedules
}) {
  const feedbacks = {
    patient_already_has_reservation: <PatientAlreadyHasReservation patientFirstName={patientFirstName}/>,
    reservation_only_allowed_for_particular_plan_group: <OnlyParticularReservation handleContinueWithReservation={handleContinueWithReservation}/>,
    no_plan_group_allows_reservation: <PlanNotAllowed />,
    patient_already_has_schedule: (
      <PatientAlreadyHasSchedule
        patientFirstName={patientFirstName}
        totalSchedules={totalSchedules}
        handleContinueWithReservation={handleContinueWithReservation} 
      />
    )
  }

  return feedbacks[message] || null
}

const Restrictions = ({
  message,
  patientFirstName,
  handleContinueWithReservation,
  showIcon = true,
  totalSchedules
}) => {
  return (
    <div className='Reservation__restrictions'>
      {showIcon && <Icon name='NoReservation' />}
      <div className='Reservation__restrictions__content'>
        {renderRestrictionFeedback({ 
          handleContinueWithReservation, 
          message, 
          patientFirstName,
          totalSchedules
        })}
      </div>
    </div>
  )
}

export default Restrictions
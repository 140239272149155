import React from 'react'

import Button from '~/components/Button'

import { externalRoutes } from '~/settings'

export const TimesUpError = () => {
  return (
    <div className='ConnectionError'>
      <div className='ConnectionError__title'>
        <p>O horário desta consulta já passou.</p>
      </div>

      <div className='ConnectionError__message'>
        <p>Volte para a sua página pessoal.</p>
      </div>

      <div className='ConnectionError__button'>
        <Button id="exit" onButtonClick={() => window.location.assign(externalRoutes.DASHBOARD)}>
          voltar</Button>
      </div>
    </div>
  )
}
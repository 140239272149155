function Brazil (phone) {
  const ddi = phone.slice(0, 2)
  const ddd = phone.slice(2, 4)
  const first = phone.slice(4, phone.length - 4)
  const last = phone.slice(phone.length - 4, )

  return `+${ddi} (${ddd}) ${first}-${last}`
}

export function formatPhoneNumber (phone) {
  const countryCode = phone.slice(0, 2)
  switch (countryCode) {
  case '55': return Brazil(phone)
  default: return phone
  }
}
import React, { useEffect, useState } from 'react'

import { endOfDay, formatISO, startOfDay } from 'date-fns'

import { Select, Typography } from '@telavita-core/react-design-kit'

import { SelectAvailabilityDate } from '..'

import { useGetProfessionalAvailabilities } from '../../hooks/useGetProfessionalAvailabilities'
import * as S from './styles'
import {
  formatSelectOptions,
  formatDateForRestriction,
  formatDateTime,
  handleSelectHour
} from './utils'

export const AvailabilityOption = ({ availabilityList, handleNonRecurrentReservationDate }) => {
  const availableDate = new Date(Object.keys(availabilityList)[0])
  const availableHours = Object.values(availabilityList)[0]
  
  const [selectedHour, setSelectedHour] = useState(null)
  const [selectedDate, setSelectedDate] = useState(() => formatDateForRestriction(availableDate))
  const [availabilityHours, setAvailabilityHours] = useState(() => availableHours)
  const [refetchData, setRefetchData] = useState(false)
  
  const formattedRestriction = [
    formatISO(startOfDay(selectedDate)),
    formatISO(endOfDay(selectedDate)),
  ]

  const { data, isFetching, refetch } = useGetProfessionalAvailabilities({ restrictions: formattedRestriction })
    
  function handleDateSelection(date) {
    setAvailabilityHours([])
    setSelectedDate(date)
    setRefetchData(true)
  }

  useEffect(() => {
    if (data) {
      const newData = data[0]

      setAvailabilityHours(Object.values(newData)[0])
      setRefetchData(false)
    }
  }, [data])

  useEffect(() => {
    if (refetchData) {
      refetch({
        exact: true,
      })
    }
  }, [refetch, refetchData])

  useEffect(() => {
    setSelectedHour(null)
  }, [selectedDate])

  useEffect(() => {
    if (!selectedHour) {
      return handleNonRecurrentReservationDate(null)
    }

    const formattedDate = formatDateTime(selectedDate, selectedHour)
    handleNonRecurrentReservationDate(formattedDate)
  }, [selectedHour, selectedDate])

  return (
    <S.Container>
      <SelectAvailabilityDate selectedDate={selectedDate} setSelectedDate={handleDateSelection} />
      <Typography variant='content2'> às </Typography>
      <Select
        options={formatSelectOptions(availabilityHours)}
        selected={selectedHour}
        onSelect={(hour) => setSelectedHour(handleSelectHour(hour))}
        label=''
        customClassName='AvailabilitySelect'
        placeholder={isFetching ? 'Carregando...' : 'Selecione'}
      />
    </S.Container>
  )
}
import { useState } from 'react'

import { useQuery } from '@tanstack/react-query'

import { usePatientScheduleList } from './usePatientScheduleList'

import { axios, endpoints } from '~/settings'

function handleRestrictionStatus(onlyParticular, defaultStatus = 'OK') {
  return onlyParticular
    ? {
      status: 'ONLY_PARTICULAR',
      message: 'reservation_only_allowed_for_particular_plan_group',
    }
    : {
      status: defaultStatus,
    }
}

const getRestrictions = async ({ patientUsername, patientPlanCode, patientTotalSchedules }) => {
  try {
    const { data } = await axios.get(endpoints.restrictions.RESTRICTIONS('patient', patientUsername), {
      params: {
        plan_code: patientPlanCode
      }
    })

    const restrictionStatus = handleRestrictionStatus(data.data?.only_particular)

    if (restrictionStatus.status === 'OK' && patientTotalSchedules > 0) {
      return {
        status: 'PATIENT_ALREADY_HAS_SCHEDULE',
        message: 'patient_already_has_schedule'
      }
    }

    return restrictionStatus
      
  } catch (err) {
    return err?.data
  }
}

export const useGetPatientRestrictions = ({
  patientUsername,
  patientPlanCode,
  isPsychologist,
  isReservationOpen,
}) => {
  const [restrictions, setRestrictions] = useState(null)
  const shouldFetch = isPsychologist && isReservationOpen
  const patientScheduleList = usePatientScheduleList(patientUsername, shouldFetch)
  
  const { refetch: refetchRestrictions } = useQuery({
    queryKey: ['GET_PATIENT_RESTRICTIONS', patientUsername],
    queryFn: () => getRestrictions({
      patientUsername, 
      patientPlanCode, 
      patientTotalSchedules: patientScheduleList ? patientScheduleList.length : 0
    }),
    onSuccess: (data) => {
      setRestrictions(data)
    },
    enabled: shouldFetch && !!patientScheduleList,
    retry: 1,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  })

  return {
    restrictions,
    setRestrictions,
    refetchRestrictions,
    patientScheduleList: patientScheduleList || [],
  }
}
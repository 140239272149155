import React from 'react'

import Button from '~/components/Button'

import { externalRoutes } from '~/settings'

export const AlreadyConnectedError = () => {
  return (
    <div className='ConnectionError'>
      <div className='ConnectionError__title'>
        <p>Você entrou na consulta em outro dispositivo</p>
      </div>

      <div className='ConnectionError__message'>
        <p>Saia dela para poder entrar por aqui.</p>
      </div>

      <div className='ConnectionError__button'>
        <Button id="exit" onButtonClick={() => {
          sessionStorage.clear()
          window.location.assign(externalRoutes.DASHBOARD)
        }}> sair</Button>
      </div>
    </div>
  )
}
import React from 'react'

import PropTypes from 'prop-types'

const MicrophoneOff = ({ fill = '#000', height = '24', width = '24' }) =>
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 24 24'>
    <path d='M0,0H24V24H0ZM0,0H24V24H0Z' fill='none' />
    <path d='M19,11H17.3a5.114,5.114,0,0,1-.43,2.05l1.23,1.23A6.585,6.585,0,0,0,19,11Zm-4.02.17c0-.06.02-.11.02-.17V5A3,3,0,0,0,9,5v.18ZM4.27,3,3,4.27l6.01,6.01V11A2.987,2.987,0,0,0,12,14a2.821,2.821,0,0,0,.65-.08l1.66,1.66A5.5,5.5,0,0,1,12,16.1,5.189,5.189,0,0,1,6.7,11H5a6.984,6.984,0,0,0,6,6.72V21h2V17.72a7.013,7.013,0,0,0,2.54-.9L19.73,21,21,19.73Z' fill={fill} />
  </svg>

MicrophoneOff.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string
}

export default MicrophoneOff

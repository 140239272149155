import React, { useEffect, useState } from 'react'

import { formatISO } from 'date-fns'

import { Button, Select, Spinner, Typography } from '@telavita-core/react-design-kit'

import { NonRecurrentReservationBox } from '~/views/Reservation/NonRecurrentReservationBox/NonRecurrentReservationBox'

import { useGetReservationAvailabilities, useUpdateReservation } from '../../hooks'
import * as S from './styles'

import { useGetProfessionalRestrictions } from '~/hooks/useGetProfessionalRestrictions'

export const RescheduleContent = ({
  reservation,
  handleSuccess 
}) => {
  const [selectedWeekDay, setSelectedWeekDay] = useState(null)
  const [selectedHour, setSelectedHour] = useState(null)
  const [nonRecurrentReservationDates, setNonRecurrentReservationDates] = useState([])
  const [disableRescheduleReservationButton, setDisableRescheduleReservationButton] = useState(false)
    
  const { dayOptions, isLoading } = useGetReservationAvailabilities({
    patientUsername: reservation.patient_username,
    professionalUsername: reservation.professional_username,
  })
  
  const { restrictions, professionalRestrictions, loadingProfessionalRestrictions } = useGetProfessionalRestrictions({
    professionalUsername: reservation.professional_username,
    patientUsername: reservation.patient_username,
    startDate: selectedHour && selectedHour.startDate,
    endDate: selectedHour && selectedHour.endDate,
    selectedWeekDay,
    selectedHour
  })
  
  const updateReservation = useUpdateReservation()
  
  const formattedNonRecurrentReservationDatesForUpdate = restrictions.map((date, index) => {
    const indexedNonRecurrentReservationDates = nonRecurrentReservationDates[index]
    return {
      oldDate: formatISO(date),
      newDate: indexedNonRecurrentReservationDates && formatISO(new Date(indexedNonRecurrentReservationDates))
    }})
      
  function handleUpdateReservation() {
    return updateReservation.mutate({
      uuid: reservation.reservation_uuid,
      nonRecurrentReservationDates: nonRecurrentReservationDates.length > 0 && formattedNonRecurrentReservationDatesForUpdate,
      professionalUsername: reservation.professional_username,
      patientUsername: reservation.patient_username,
      recurrentStartDate: formatISO(professionalRestrictions.firstAvailableStartDate),
      recurrentEndDate: formatISO(professionalRestrictions.firstAvailableEndDate)
    })
  }

  function handleNonRecurrentReservationDate(date, slotIndex) {
    const newNonRecurrentReservationDates = [...nonRecurrentReservationDates]
    newNonRecurrentReservationDates[slotIndex] = date
    setNonRecurrentReservationDates(newNonRecurrentReservationDates)
  }

  const showSubmitButton = selectedWeekDay && selectedHour && !loadingProfessionalRestrictions
  const hasProfessionalRestrictions = restrictions.length > 0
  const hasNonRecurrentReservationDates = nonRecurrentReservationDates
    .every(date => Boolean(date)) && nonRecurrentReservationDates.length > 0

  useEffect(() => {
    if (hasProfessionalRestrictions || !hasNonRecurrentReservationDates) {
      setDisableRescheduleReservationButton(true)
    } 
  
    if ((hasProfessionalRestrictions && hasNonRecurrentReservationDates) || !hasProfessionalRestrictions) {
      setDisableRescheduleReservationButton(false)
    }
  
  }, [
    nonRecurrentReservationDates, 
    restrictions,
    hasNonRecurrentReservationDates, 
    hasProfessionalRestrictions
  ])

  // Reset na hora selecionada quando o dia da semana é alterado
  useEffect(() => {
    if (selectedWeekDay) {
      setSelectedHour(null)
    }
  }, [selectedWeekDay])

  useEffect(() => {
    if (updateReservation.isSuccess) {
      handleSuccess({
        newRecurrentStartDate: professionalRestrictions.firstAvailableStartDate,
        exceptions: formattedNonRecurrentReservationDatesForUpdate,
      })
    }
  }, [
    handleSuccess, 
    updateReservation.isSuccess, 
    formattedNonRecurrentReservationDatesForUpdate, 
    professionalRestrictions.firstAvailableStartDate
  ])
    
  if (isLoading) {
    return (
      <S.SpinnerWrapper>
        <Spinner />
      </S.SpinnerWrapper>
    )
  }

  return (
    <S.Container>
      <Select 
        label='Escolha um dia da semana'
        options={dayOptions}
        onSelect={(value) => setSelectedWeekDay(value)}
        selected={selectedWeekDay}
        position='fixed'
      />
        
      {selectedWeekDay && (
        <Select 
          label='Escolha um de seus horários recorrentes'
          options={selectedWeekDay?.availabilities}
          onSelect={(value) => setSelectedHour(value)}
          selected={selectedHour}
          position='fixed'
        />
      )}

      {loadingProfessionalRestrictions && (
        <S.SpinnerWrapper>
          <Spinner />
        </S.SpinnerWrapper>
      )}

      {!loadingProfessionalRestrictions && hasProfessionalRestrictions && (
        <NonRecurrentReservationBox
          patientUsername={reservation.patient_username}
          restrictions={restrictions} 
          handleNonRecurrentReservationDate={handleNonRecurrentReservationDate}
        />
      )}

      {showSubmitButton && (
        <S.ButtonWrapper>
          <Typography variant='content3' center>
            *Brasil (UTC−3) - Horário de Brasília Padrão 00h00 a 23h59
          </Typography>
          <Button
            variant='contained'
            uppercase
            disabled={disableRescheduleReservationButton || updateReservation.isLoading}
            onClick={handleUpdateReservation}
          >
            {
              updateReservation.isLoading
                ? <Spinner customClassName='customSpinner'/>
                : 'Concluir Modificação'
            }
          </Button>
        </S.ButtonWrapper>
      )}
    </S.Container>
  )
}
import React from 'react'

import Button from '~/components/Button'
import CustomIcons, { iconList } from '~/components/CustomIcons'

import { externalRoutes } from '~/settings'
import { colors } from '~/utils/stylesConstants'

export const UnknownErrorMessage = () => {
  const handleBtnClick = () => {
    sessionStorage.clear()
    window.location.assign(externalRoutes.MAIN_WEBAPP)
  }

  return (
    <div className="FeedbackMessageBox">
      <div className="FeedbackMessageBox__icon">
        <CustomIcons icon={iconList.Alert} fill={colors.RED_01} />
      </div>

      <div className="FeedbackMessageBox__title">
        <p>Ops, houve algum problema</p>
      </div>

      <div className='FeedbackMessageBox__message'>
        <p>Saia e tente entrar novamente.</p>
      </div>

      <div className='FeedbackMessageBox__action'>
        <Button id='exit' classModifier='reverse' type='link' onButtonClick={handleBtnClick}>
          sair
        </Button>
      </div>
    </div>
  )
}
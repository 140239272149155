import React from 'react'

import { IconButton } from '@telavita-core/react-design-kit'

const CallEndButton = ({ handleLeaveCall, hasAlt = false }) => {
  return (
    <IconButton
      icon='CallEnd'
      variant='contained'
      color='danger'
      tooltip={hasAlt ? 'Sair' : null}
      onClick={handleLeaveCall}
    />
  )
}

export default CallEndButton

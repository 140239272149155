import React from 'react'

import { formatISO } from 'date-fns'

import { Paper, Spinner, Typography } from '@telavita-core/react-design-kit'

import { AvailabilityOption, UnavailabilityInfo } from './components'
import { useGetProfessionalAvailabilities } from './hooks/useGetProfessionalAvailabilities'
import * as S from './styles'

import { colors } from '~/utils/stylesConstants'

export const NonRecurrentReservationBox = ({ restrictions, handleNonRecurrentReservationDate }) => {
  const { data, isFetching, refetch, isError } = useGetProfessionalAvailabilities({ 
    restrictions: restrictions.map((date) => formatISO(new Date(date))) 
  })

  return ( 
    <S.Container>
      <UnavailabilityInfo
        restrictions={restrictions}
        handleShowAvailabilityOptions={() => refetch()}
        alreadyHasData={data && data.length > 0}
      />

      {isFetching && (
        <S.SpinnerWrapper>
          <Spinner />
        </S.SpinnerWrapper>
      )}

      {isError && (
        <Paper variant='danger'>
          <Typography variant='body1' center inlineStyles={{ color: colors.WHITE }}>
            Ocorreu um erro ao carregar as disponibilidades. Tente novamente!
          </Typography>
        </Paper>
      )}

      {data && data.map((availabilityList, index) => (
        <AvailabilityOption 
          key={index} 
          availabilityList={availabilityList} 
          handleNonRecurrentReservationDate={(singleDate) => handleNonRecurrentReservationDate(singleDate, index)}
        />
      ))}

    </S.Container>
  )
}
import React, { useState, useEffect } from 'react'

import clsx from 'clsx'

import { IconButton, FloatCard, useDimensions } from '@telavita-core/react-design-kit'

const OptionsButton = ({ 
  onChatClick = null, 
  onRescheduleReservationClick = null,
  onReservationClick = null, 
  onSettingsClick = null, 
  isProfessional, 
  onPatientDetailsClick = null, 
  showReservationOption = false,
  showRescheduleReservationOption = false,
  isOpen 
}) => {
  const { isMobile } = useDimensions()

  const [open, setOpen] = useState(false)

  const handleClick = () => { setOpen(open => !open) }

  const handleChatClick = () => {
    onChatClick()
    setOpen(false)
  }

  const handleRescheduleReservationClick = () => {
    onRescheduleReservationClick()
    setOpen(false)
  }

  const handleReservationClick = () => {
    onReservationClick()
    setOpen(false)
  }

  const handlePatientDetailsClick = () => {
    onPatientDetailsClick()
    setOpen(false)
  }

  function handleSettingsClick() {
    onSettingsClick()
    setOpen(false)
  }

  const professionalOptions = [
    { code: 1, name: 'Chat', func: handleChatClick },

    /* Nesse momento iremos deixar a opção de remarcar a reserva totalmente desabilitada até que
      a correção do fluxo seja feita no backend, isso ocorrerá após o projeto ir para produção.
    */
     
    // TODO: remover comentários após a correção do fluxo no backend e habilitar novamente a opção de remarcar reserva.
    // showRescheduleReservationOption && { code: 2, name: 'Mudar dia da reserva', func: handleRescheduleReservationClick },

    showReservationOption && { code: 3, name: 'Reservar horário', func: handleReservationClick },
    { code: 4, name: 'Detalhes do paciente', func: handlePatientDetailsClick },
    { code: 5, name: 'Configurações', func: handleSettingsClick },
  ].filter(Boolean)

  const patientOptions = [
    { code: 1, name: 'Chat', func: handleChatClick },
    { code: 2, name: 'Configurações', func: handleSettingsClick },
  ]

  useEffect(() => {
    function closeList() {
      if (isOpen && isMobile) {
        setOpen(false)
      }
    }

    closeList()
  }, [isOpen, isMobile])

  return (
    <div>
      <IconButton
        variant='outlined'
        icon='Options'
        size={56}
        onClick={handleClick}
      />
      {
        open && (
          <FloatCard
            customClassName={isProfessional ? clsx({
              'CallRoomOptions--professional': true,
              'CallRoomOptions--psychologist': showReservationOption
            }) : 'CallRoomOptions--patient'}
            options={isProfessional ? professionalOptions : patientOptions}
            setOpen={setOpen}
          />
        )
      }
    </div>
  )
}


export default OptionsButton

import styled, { css, keyframes } from 'styled-components'

const blink = keyframes`
   0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
`

export const Wrapper = styled.div`
  > button {
    display: flex;
    align-items: center;
    height: 0;
    padding: 0;
    transition: all 0.2s ease-in-out;
    gap: 5px;
  }

  .DatePickerText {
    ${({ isWeeklyCalendarOpen }) => isWeeklyCalendarOpen &&
      css`animation: ${blink} 0.7s linear infinite;`}
  }
`

export const Content = styled.div`
  width: 332px;
  padding: 40px;
  overflow: hidden;
`

export const PopoverWrapper = styled.div`
  [data-tippy-root] {
    margin-top: 10px;
    width: 330px;
    transform: translate(-29px, 40px) !important;
  }
`
import React from 'react'

import { Typography } from '@telavita-core/react-design-kit'

import * as S from './styles'

export const PlanNotAllowed = () => {
  return (
    <S.PlanNotAllowedWrapper>
      <Typography center variant='content2'>
        O paciente não possui nenhum convênio que permita a criação da reserva.
      </Typography>
    </S.PlanNotAllowedWrapper>
  )
}
  
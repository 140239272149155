import React from 'react'

import Spinner from '~/components/Spinner'

export const PreparingRoomMessage = () => {
  return (
    <div className="FeedbackMessageBox">
      <div className="FeedbackMessageBox__icon">
        <Spinner />
      </div>

      <div className="FeedbackMessageBox__title">
        <p>Preparando...</p>
      </div>

      <div className='FeedbackMessageBox__message'>
        <p>{'Você poderá iniciar a consulta\n em segundos.'}</p>
      </div>
    </div>
  )
}
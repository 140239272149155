import React from 'react'

import { Drawer, Typography, Avatar, Button } from '@telavita-core/react-design-kit'

import AvatarIcon from '../../static/svg/icon_avatar.svg'
import { ReactComponent as IconCall } from '../../static/svg/icon_call.svg'
import { ReactComponent as IconMail } from '../../static/svg/icon_mail.svg'

import { formatCpf } from '~/utils/formatCpf'
import { formatPhoneNumber } from '~/utils/formatPhoneNumber'

const PatientDetails = ({ open, setOpen, patientData }) => {
  return (
    <Drawer
      open={open}
      setOpen={setOpen}
      anchor='right'
    >
      <div className='PatientDetails'>
        <Avatar 
          alt={`Uma imagem do paciente ${patientData.full_name}`}
          src={patientData?.picture_url ? patientData.picture_url : AvatarIcon}
          size='medium'
        />
        <div className='PatientDetails__NameWrapper'>
          <Typography variant='content2'>
            Paciente
          </Typography>
          <Typography variant='header4'>
            {patientData.full_name}
          </Typography>
        </div>
        <div className='PatientDetails__PersonalDataWrapper'>
          {patientData?.cpf && (
            <Typography variant='content2'>
              CPF: {formatCpf(patientData.cpf)}
            </Typography>
          )}
          <div>
            <IconCall />
            <Typography variant='content2'>
              {formatPhoneNumber(patientData.cellphone_number)}
            </Typography>
          </div>
          <div>
            <IconMail />
            <Typography variant='content2'>
              {patientData.username}
            </Typography> 
          </div>
        </div>
        <Button onClick={() => setOpen(false)} variant='contained' customClassName='PatientDetails__CloseButton'>
          Fechar
        </Button>
      </div>
    </Drawer>
  )
}

export default PatientDetails